.cart-store-list{
	height: 160px;
	display: flex;
	gap: 12px;
	flex-direction: column;
}

.cart-store-list-title{
	font-family: 'Inter';
	font-size: 24px;
	font-weight: 500;
	line-height: 40px;
	letter-spacing: 0.02em;
	text-align: left;
}

.cart-store-list-items{
	display: flex;
	overflow: auto;
	padding: 8px 0;
	scrollbar-width: none;
	gap: 12px;
}

@media (max-width: 1024px){
	.cart-store-list-title{
		font-size: 18px;
		line-height: 28px;
	}
}

