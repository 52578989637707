.recipe-page-comment-share-card-container{
	width: 0px;
	height: 0;
	position: relative;
	display: flex;
}
.recipe-page-comment-share-card{
	width: 256px;
	display: flex;
	flex-direction: column;
	border-radius: 8px;
	padding: 8px 0;
	background-color: white;
	box-shadow: 0px 4px 14px -6px #18274B1F;
	z-index: 2;
	position: absolute;
	right: 0;
	top: 20px;
	box-sizing: border-box;
}
.recipe-page-comment-share-item{
	width: 100%;
	height: 44px;
	padding: 11px 44px 11px 16px;
	display: flex;
	gap: 12px;
	align-items: center;
	box-sizing: border-box;
	cursor: pointer;
}
.recipe-page-comment-share-item:hover{
	background-color: #F9FAFB;
}
.recipe-page-comment-commenter-name{
	cursor:pointer;
}
.recipe-page-comment-share-item p{
	font-family: Inter;
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
	letter-spacing: 0.02em;
	text-align: left;
	color: #344054;
}

.recipe-page-comment-share-card-title{
	display: none;
}

.recipe-page-comment-share-card-exit{
	flex: 1;
	width: 100%;
}

.recipe-page-comment-delete-container{
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #10182899; 
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 16px;
	box-sizing: border-box;
	z-index: 5;
}

.recipe-page-comment-delete-card{
	width: 600px;
	padding: 24px;
	display: flex;
	flex-direction: column;
	background-color: white;
	box-shadow: 0px 8px 22px -6px #18274B1F;
	border-radius: 16px;
	box-sizing: border-box;
}
.recipe-page-comment-delete-card>div{
	display: flex;
	height: 44px;
	gap: 12px;
	justify-content: end;
	width: 100%;
	margin-top: 24px;
}

.recipe-page-comment-delete-card h6{
	font-family: Inter;
	font-size: 18px;
	font-weight: 500;
	line-height: 28px;
	letter-spacing: 0.02em;
	text-align: left;
	color: #101828;
}

.recipe-page-comment-delete-card>p{
	font-family: Inter;
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
	letter-spacing: 0.02em;
	text-align: left;
	color: #667085;
	margin-top: 4px;
}

.color-red{
	background-color: #D92D20;
}

.recipe-page-comment-edit-exit{
	flex: 1;
	width: 100%;
}
.recipe-page-comment-edit{
	position: relative;
	z-index: 5;
}
.recipe-page-comment-edit-button{
	display: flex;
	height: 22px;
	gap: 8px;
	position: absolute;
	right: 28px;
	bottom: 16px;
}

.recipe-page-comment-edit-confirm{
	font-family: Inter;
	font-size: 14px;
	font-weight: 500;
	line-height: 22px;
	letter-spacing: 0.02em;
	color: #FEC848;
}

.recipe-page-comment-edit-img{
	display: none;
}
.recipe-page-comment-edit>p{
	font-family: Inter;
	font-size: 14px;
	font-weight: 500;
	line-height: 22px;
	letter-spacing: 0.02em;
	text-align: center;

	color: #344054;
	display: none;
}

.recipe-page-comment-edit-content{
	display: flex;
	padding: 11px 16px;
	gap: 20px;
}

.recipe-page-comments-reply{
	width: fit-content;
}

@media (max-width: 767px){
	.recipe-page-comment-share-card-container{
		position: fixed;
		z-index: 2;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		background-color: #10182899;
	}
	.recipe-page-comment-share-card{
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		margin-top: auto; 
		display: flex;
		gap: 12px;
		height: fit-content;
		padding: 0;
		border-radius: 0;
		box-shadow: none;
		border: none;
		padding-bottom: 12px;
		padding-left: 16px;
		padding-right: 16px;
	}
	.recipe-page-comment-share-card img{
		display: none;
	}
	.recipe-page-comment-share-card-title{
		margin-top: auto; 
		width: 100%;
		background-color: white; 
		height: 44px;
		border-bottom: 1px #EAECF0 solid; 
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;

		font-family: 'Inter'; 
		font-size: 14px;
		font-weight: 500;
		line-height: 22px;
		letter-spacing: 0.02em;
		text-align: center;
		color: #344054;

		border-radius: 8px 8px 0 0;
	}
	.recipe-page-comment-share-item p{
		padding: 0;
		font-family: Inter;
		font-size: 12px;
		font-weight: 400;
		line-height: 20px;
		letter-spacing: 0.02em;
		text-align: center;
	}
	.recipe-page-comment-share-item{
		padding: 10px 16px;
		border-radius: 100px;
		border: 1px #D0D5DD solid;
		box-shadow: 0px 1px 2px 0px #18274B14;
		background-color: white;
		cursor: pointer;
		display: flex;
		justify-content: center;
	}
	.recipe-page-comment-delete-button{
		background-color: #D92D20;
	}
	.recipe-page-comment-delete-button:hover{
		background-color: #D92D20;
	}
	.recipe-page-comment-delete-button p{
		color: white;
	}

	.recipe-page-comment-delete-card{
		width: 100%;
	}
	.recipe-page-comment-delete-card>div{
		flex-direction: column-reverse;
		height: fit-content;
	}

	.recipe-page-comment-edit{
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		background-color: #10182899;
		box-sizing: border-box;
		justify-content: end;
	}
	.recipe-page-comment-edit>p{
		display: block;
		background-color: white;
		height: 44px;
		text-align: center;
		padding-top: 11px;
		box-sizing: border-box;
		border-bottom: 1px #EAECF0 solid;
	}
	.recipe-page-comment-edit-content{
		background-color: white;
	}
	.recipe-page-comment-edit-content>textarea{
		display: block;
		width: 100%;
		height: 100%;
	}
	.recipe-page-comment-edit-img{
		display: block;
	}
	.recipe-page-comment-edit-button{
		bottom: 16px;
		right: 26px;
	}
}
