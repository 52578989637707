.user-header{
	width: 100%;
	height: 80px;
	border-bottom: 1px solid black;
	line-height: 81px;
	text-align: center;
	vertical-align: middle;
}

.user-nav-bar{
	width: 319px;
	height: calc(100vh - 81px);
	border-right: 1px solid black;

	line-height: calc(100vh - 81px);
	text-align: center;
	vertical-align: middle;
}

.user-main-content{
	display: flex;
}

.user-main-collection-content{
	display: flex;
	padding: 80px;
	width: 100%;
	background-color: #F9FAFB;
}
@media (max-width: 1024px){
	.user-header{
		height: 48px;
		line-height: 48px;
	}
	.user-nav-bar{
		display: none;
	}

	.user-main-collection-content{
		padding: 20px;
		min-height: calc(100vh - 89px)
	}
}


