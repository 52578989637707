.user-profile-content{
	padding: 80px;
	width: 100%;
	background-color: #F9FAFB;
	overflow: auto;
}

@media (max-width: 767px){
	.user-profile-content{
		padding: 20px;
		min-height: calc(100vh - 89px)
	}
}

