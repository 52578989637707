.cart-page{
	display: flex;
	min-width: calc(100vw - 320px);
	min-height: calc(100vh - 77px);
	box-sizing: border-box;
	padding-bottom: 100px;
}

.cart{
	display: flex;
	width: calc(100% - 320px);
	margin-top: 77px;
	margin-left: 320px;
	flex-direction: column;
	padding: 48px;
	gap: 48px;
	box-sizing: border-box;
}

@media (max-width: 1024px){
	.cart{
		margin-top: 48px;
		margin-left: 0;
		width: 100%;
		padding: 20px;
		padding-bottom: 100px;
	}
}


