body {
    margin: 0;
    padding: 0;
    height: 100%;
    background-color: #F9FAFB;
}

.contact-page {
    margin-top: 73px;
    padding: 48px 80px 318px 80px; /* 顺时针方向分别为：上、右、下、左 */
    box-sizing: border-box;
    height: calc(100vh - 73px);
    overflow-x: hidden;
    width: calc(100% - 320px);
    justify-content: center;
    align-items: center;
    background-color: #F9FAFB;
}

.contact-container {
    width: 100%;
    max-width: 960px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;
}

.contact-title {
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #DC6803;
    margin-bottom: 20px;
    display: flex;
}

.name-inputs {
    font-family: 'Inter', sans-serif;
    display: flex;
    gap: 20px; /* 控制两个输入框之间的距离 */
    width: 100%;
    max-width: 960px;
    box-sizing: border-box;
}

.input-group {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 960px;
}

.input-group label,
.summary-input-group label,
.bigger-summary-input-group label{
    font-family: 'Inter', sans-serif;
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    color: #344054;
}

.input-group input {
    width: 100%;
    height: 44px;
    padding: 9px 8px 9px 16px;
    border-radius: 8px;
    border: 1px solid #D0D5DD;
    font-size: 16px;
    box-sizing: border-box;
    outline: none;
}


.bigger-summary-input-group,
.summary-input-group {
    font-family: 'Inter', sans-serif;
    margin-top: 12px;
    width: 100%;
    max-width: 960px;
    display: flex;
    flex-direction: column;
}

.summary-input-group input {
    width: 100%;
    height: 44px;
    padding: 9px 8px 9px 16px;
    border-radius: 8px;
    border: 1px solid #D0D5DD;
    font-size: 16px;
    box-sizing: border-box;
    outline: none;
    color: #101828;
}

.summary-input-group input::placeholder {
    color: #98A2B3;
}


.bigger-summary-input-group textarea::placeholder {
    color: #98A2B3;
    padding-top: 0; /* 控制 placeholder 距离顶部的距离 */
}

.bigger-summary-input-group textarea {
    width: 100%;
    height: 104px;
    padding: 9px 8px 9px 16px;
    border-radius: 8px;
    border: 1px solid #D0D5DD;
    font-size: 16px;
    box-sizing: border-box;
    outline: none;
    color: #101828;
    resize: none; /* 禁用手动调整 textarea 大小 */
}



.category-container{
    font-family: 'Inter', sans-serif;
    max-width: 960px;
    width: 100%;
}


.contact-button-group {
    display: flex;
    justify-content: flex-end; /* 将按钮组对齐到右侧 */
    gap: 8px; /* 按钮之间的间距 */
    margin-top: 48px; /* 距离上一个元素48px */
    width: 100%;
    max-width: 962px;
}

.button-group button {
    width: 80px; /* 按钮宽度 */
    height: 44px; /* 按钮高度，使用自定义变量 */
    padding: 11px 16px; /* 内边距：上下0px，左右16px */
    border-radius: 100px; /* 圆角 */
    font-size: 14px;
    cursor: pointer;
    box-sizing: border-box;
    outline: none; /* 移除按钮的默认 outline */
    border: 1px solid #D0D5DD; /* 明确设置边框颜色为 #D0D5DD */
}



.contact-next-button {
    font-family: 'Inter', sans-serif;
    width: 81px; /* 按钮宽度 */
    height: 44px; /* 按钮高度，使用自定义变量 */
    padding: 11px 16px; /* 内边距：上下0px，左右16px */
    border-radius: 100px; /* 圆角 */
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    cursor: pointer;
    box-sizing: border-box;
    outline: none; /* 移除按钮的默认 outline */
    border: 1px solid #FEC84B; 
    background-color: #FFFFFF; 
    color: #DC6803; /* 文本颜色为白色 */
    display: inline-block;
    text-align: center;
}

.contact-next-button:hover {
    background-color: #FFFAEB; /* hover状态下的背景颜色为 #DC6803 */
}

@media (min-width: 1025px) {
    .contact-page {
        display: flex;
        justify-content: center; /* 将容器水平居中 */
        padding:30px;
    }

    .contact-container {
        margin-left: 0; /* 移除左边距 */
        margin-right: 0; /* 移除右边距 */
        max-width: 960px; /* 可选：设置最大宽度，以防止内容太宽 */
    }
}


/* 针对屏幕尺寸为1441px及以上的媒体查询 */
@media (min-width: 1025px) {
    .contact-page {
        display: flex;
        justify-content: center; /* 将页面容器水平居中 */
        padding: 30px; /* 设置容器的内边距 */
    }

    .contact-container {
        margin-left: 0; /* 移除左边距 */
        margin-right: 0; /* 移除右边距 */
        max-width: 960px; /* 设置最大宽度，以防止内容过宽 */
    }
}

/* 针对屏幕尺寸在768px到1440px之间的媒体查询 */
@media (min-width: 768px) and (max-width: 1024px) {
    .contact-page {
        display: flex;
        justify-content: center; /* 将页面容器水平居中 */
        align-items: center; /* 垂直居中对齐 */
        margin-left: 150px; /* 设置左边距 */
        margin-right: 0; /* 移除右边距 */
        padding: 24px; /* 保持上、下的padding一致 */
        padding-bottom: 100px; /* 增加底部内边距，防止与导航栏重叠 */
        height: auto; /* 使高度自适应 */
    }

    .contact-container {
        margin: 0 auto; /* 将容器水平居中 */
        max-width: 960px; /* 保持容器的最大宽度 */
        width: 100%; /* 使容器宽度适应父容器 */
    }
}

/* 针对小屏幕（最大宽度为768px）的媒体查询 */
@media (max-width: 768px) {
    .contact-page {
        display: flex;
        justify-content: center; /* 将页面容器水平居中 */
        align-items: center; /* 将页面内容垂直居中 */
        margin-top: 48px; /* 设置顶部外边距 */
        margin-left: 0; /* 移除左边距 */
        margin-right: 0; /* 移除右边距 */
        padding: 24px; /* 为小屏幕增加合适的内边距 */
        padding-bottom: 100px; /* 增加底部内边距，防止与导航栏重叠 */
        height: auto; /* 使高度自适应 */
        width: 100%; /* 使页面宽度填充满整个屏幕 */
    }

    .contact-container {
        margin: 0 auto; /* 将容器居中 */
        max-width: 100%; /* 确保容器宽度自适应 */
        box-sizing: border-box; /* 包括内边距和边框 */
    }

    /* 调整 name-inputs 使 Last Name 在 First Name 下方 */
    .name-inputs {
        flex-direction: column; /* 垂直排列输入框 */
        gap: 0; /* 控制两个输入框之间的距离 */
    }

    .name-inputs .input-group {
        margin-top: 20px; /* 增加上下元素的间距 */
    }

    .name-inputs .input-group:first-child {
        margin-top: 0; /* 确保第一个输入框没有上边距 */
    }

    /* 调整 bigger-summary-input-group 和 summary-input-group 垂直布局 */
    .bigger-summary-input-group,
    .summary-input-group {
        width: 100%; /* 确保输入框宽度填满容器 */
        margin-top: 20px; /* 增加与上一个元素的距离 */
    }

    .summary-input-group input {
        width: 100%; /* 确保输入框宽度填满容器 */
        height: 44px; /* 固定输入框高度 */
        padding: 9px 16px; /* 调整内边距 */
        border-radius: 8px; /* 边框圆角 */
        border: 1px solid #D0D5DD; /* 边框颜色 */
        font-size: 16px;
        box-sizing: border-box;
        outline: none;
    }

    .bigger-summary-input-group textarea{
        width: 100%; /* 确保输入框宽度填满容器 */
        height: 104px; /* 固定输入框高度 */
        padding: 9px 16px; /* 调整内边距 */
        border-radius: 8px; /* 边框圆角 */
        border: 1px solid #D0D5DD; /* 边框颜色 */
        font-size: 16px;
        box-sizing: border-box;
        outline: none;
    }



    /* 调整 contact-button-group 的布局 */
    .contact-button-group {
        margin-top: 48px; /* 增加与上方元素的距离 */
        width: 100%; /* 确保按钮组宽度填满容器 */
        display: flex;
        justify-content: center; /* 将按钮组对齐到中间 */
        gap: 8px; /* 控制按钮之间的距离 */
    }

    .contact-next-button {
        width: 100%; /* 按钮宽度填满容器 */
        max-width: 326px; /* 设置最大宽度 */
        height: 44px; /* 固定按钮高度 */
        padding: 11px 16px; /* 调整内边距 */
        border-radius: 100px; /* 圆角 */
        font-size: 14px;
        cursor: pointer;
        box-sizing: border-box;
        outline: none;
        border: 1px solid #FEC84B; 
        background-color: #FFFFFF; 
        color: #DC6803; /* 文本颜色 */
        display: inline-block;
        text-align: center;
    }

    .contact-next-button:hover {
        background-color: #FFFAEB; /* hover状态下的背景颜色 */
    }
}


/* 控制错误消息的样式 */
.contactus-error-message {
    color: red;
    background-color: #ffe6e6; /* 背景颜色 */
    padding: 10px;
    margin-top: 10px; /* 与输入框的间距 */
    border: 1px solid red;
    border-radius: 4px;
    font-size: 14px;
}


/* 背景变暗 */
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; 
}

/* 转圈动画样式 */
.loading-spinner {
    border: 8px solid rgba(0, 0, 0, 0.1);
    border-left-color: #ff6f00;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

