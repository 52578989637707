.components-saved-recipe{
}

.components-saved-card{
	width: 256px;
	background-color: white;
	border-radius: 8px;
	padding: 8px 0;
	box-shadow: 0px 4px 14px -6px #18274B1F;
	position: absolute;
}


.components-saved-item{
	width: 100%;
	display: flex;
	padding: 11px 44px 11px 16px;
	box-sizing: border-box;
	gap: 12px;
}
.components-saved-item:hover{
	background-color: #F9FAFB;
}


.components-saved-checkbox{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 20px;
	height: 20px;
	box-sizing: border-box;
	border: 1px #D0D5DD solid;
	border-radius: 4px;
}
.components-saved-item:hover .components-saved-checkbox{
	background-color: #FEF0C7;
}
.components-saved-checkbox:hover{
	background-color: #FEF0C7;
}
.components-saved-item:active .components-saved-checkbox{
	background-color: #FEF0C7;
	background-color: white;
	border: 1px #FEC84B solid;
}
.components-saved-checkbox:active{
	background-color: white;
	border: 1px #FEC84B solid;
}
.components-saved-checkbox-active{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 20px;
	height: 20px;
	box-sizing: border-box;
	border-radius: 4px;
	background-color: #FFFAEB;
	border: 1px #DC6803 solid;
}
.components-saved-item:hover .components-saved-checkbox-active{
	background-color: #FEF0C7;
}
.components-saved-checkbox-active:hover{
	background-color: #FEF0C7;
}
.components-saved-item:active .components-saved-checkbox-active{
	background-color: #FFFAEB;
}
.components-saved-checkbox-active:active{
	background-color: #FFFAEB;
}

.components-saved-item p{
	font-family: 'Inter';
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
	letter-spacing: 0.02em;
	color: #344054;
}

.components-saved-separator{
	margin-top: 8px;
	margin-bottom: 8px;
	width: 100%;
	height: 0;
	box-sizing: border-box;
	border-top: 1px #EAECF0 solid;
}
.components-saved-mb{
	display: none;
}

.components-saved-header{
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
}
.components-saved-header p{
	font-size: 14px;
	font-weight: 500;
	line-height: 22px;
	letter-spacing: 0.02em;
	text-align: center;
	color: #344054;
	font-family: 'Inter';
}

.components-saved-background{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #10182899;
}

@media(max-width: 767px){
	.components-saved-dt{
		display: none;
	}
	.components-saved-mb{
		display: block;
		position: fixed;
		left: 0;
		width: 100%;
		bottom: 0;
		
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
	}
}


