:root {
    --desktop-nav-bar-width: 320px;
    --desktop-header-height: 77px;
    --desktop-offset-height: 48px;

    --tablet-header-height: 48px;
    --tablet-nav-bar-height: 72px;
    --tablet-offset-height: 48px;

    --mobile-header-height: 48px;
    --mobile-nav-bar-height: 72px;
    --mobile-offset-height: 24px;
}


/* desktop */
@media screen and (min-width: 1025px) {
    .container-wrapper {
        display: flex;
        position: relative;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: 800px;
        margin: 0 auto;
        padding-left: var(--desktop-nav-bar-width);
        padding-top: calc(var(--desktop-header-height) + var(--desktop-offset-height));
        padding-bottom: var(--desktop-offset-height);
    }


    .mobile {
        display: none;
    }
}

/* tablet */
@media (min-width: 769px) and (max-width: 1024px) {
    .container-wrapper {
        display: flex;
        position: relative;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: 800px;
        margin: 0 auto;
        padding-top: calc(var(--tablet-header-height) + var(--tablet-offset-height));
        padding-bottom: calc(var(--tablet-nav-bar-height) + var(--tablet-offset-height));
    }

    .mobile {
        display: none;
    }
}

/* mobile */
@media screen and (max-width: 768px) {
    .container-wrapper {
        padding: 24px 20px;
        padding-top: calc(var(--mobile-header-height) + var(--mobile-offset-height));
        padding-bottom: calc(var(--mobile-nav-bar-height) + var(--mobile-offset-height));
    }

    .desktop {
        display: none;
    }
}
