body {
    margin: 0;
    padding: 0;
    height: 100%;
    background-color: #F9FAFB;
}

  
    .reward-redeemed-page {
        margin-top: 73px;
        margin-left: 320px;
        padding: 48px 160px 143px 160px; /* 顺时针方向分别为：上、右、下、左 */
        box-sizing: border-box;
        height: calc(100vh - 73px);
        overflow-x: hidden;
        /* overflow-y: auto; */
        width: calc(100% - 320px);
        justify-content: center;
        align-items: center;
        background-color: #F9FAFB;
    }
    
    .desktop-image{
        display: block;
        max-width: 800px;
        width: 100%;
        height: auto;

    }

    .reward-redeemed-container {
        max-width: 800px;
        width: 100%;
        text-align: center;
        margin-top: 48px; /* 与上方图片的距离 */
    }
    
    .redeemed-title-container {
        max-width: 800px;
        width: 100%;
        height: 50px;
    }
    
    .redeemed-title {
        font-family: 'Inter', sans-serif;
        font-size: 30px;
        font-weight: 500;
        line-height: 50px;
        color: #344054;
        margin: 0;
        text-align: center;
    }


    .redeemed-info-container {
        width: 100%;
        max-width: 800px;
        height: 66px;
        margin-top: 8px; /* 与上一个元素的距离 */
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        color: #667085;
        text-align: center;
        line-height: 22px;
        box-sizing: border-box;
    }

    .redeemed-button {
        width: 100%;
        max-width: 175px;
        height: 44px;
        padding: 0 16px;
        border-radius: 100px;
        border: 1px solid #D0D5DD; /* 边框颜色 */
        background-color: #FFFFFF; /* 按钮背景色 */
        color: #344054; /* 按钮文字颜色 */
        font-size: 14px;
        line-height: 22px;
        font-weight: 500;
        cursor: pointer;
        outline: none;
        text-align: center;
        box-sizing: border-box;
        margin-top: 48px;
    }
    
    .redeemed-button:hover {
        background-color: #D0D5DD; /* hover 状态下的背景颜色 */
    }

    @media (min-width: 1025px) {
        .reward-redeemed-page {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }
    
        .reward-redeemed-container {
            margin-top: 48px; /* 容器居中 */
        }
    
        .desktop-image {
            margin: 0 auto; /* 图片居中 */
        }

        .mobile-image{
            display: none;
        }
    }

    /* 针对屏幕尺寸在768px到1440px之间的媒体查询 */
@media (min-width: 768px) and (max-width: 1024px) {
    .reward-redeemed-page {
        margin-top: 73px;
        margin-left: auto; /* 取消固定左边距，使其自动适应 */
        margin-right: auto; /* 确保右边距自动适应 */
        padding: 48px 20px; /* 缩小左右内边距，避免内容过窄 */
        box-sizing: border-box;
        height: auto; /* 使高度自适应 */
        overflow-x: hidden;
        justify-content: center;
        align-items: center;
        background-color: #F9FAFB;
        width: 100%; /* 确保宽度填充满整个屏幕 */
    }

    .desktop-image {
        margin: 0 auto; /* 图片居中 */
    }

    .mobile-image {
        display: none;
    }

    .reward-redeemed-container {
        margin: 0 auto; /* 将容器水平居中 */
        max-width: 960px; /* 调整最大宽度为960px，使其更宽一些 */
        width: 100%; /* 使容器宽度适应父容器 */
        margin-top: 48px; /* 与上方图片的距离 */
    }

    .redeemed-info-container,
    .redeemed-title-container {
        max-width: 960px; /* 调整最大宽度 */
        width: 100%; /* 保持宽度与父容器一致 */
        height: auto; /* 高度自适应 */
    }
}



    /* 针对小屏幕的媒体查询 */
@media (max-width: 768px) {
    .reward-redeemed-page {
        margin-top: 48px;
        padding: 24px; /* 适当缩小内边距，适应小屏幕 */
        box-sizing: border-box;
        height: 850px; /* 使高度自适应 */
        overflow-x: hidden;
        justify-content: center;
        align-items: center;
        width: 100%; /* 使页面宽度填充满整个屏幕 */
        margin-left: auto; /* 取消固定左边距，使其自动适应 */
        margin-right: auto; /* 确保右边距自动适应 */
        padding-bottom: 25px; /* 减少底部内边距，减少与导航栏的空白 */
    }

    .desktop-image {
        display: none; /* 隐藏桌面端图片 */
    }

    .mobile-image {
        display: block; /* 显示移动端图片 */
        width: 100%; /* 使图片填满容器宽度 */
        max-width: 342px; /* 限制最大宽度 */
        height: auto; /* 让图片高度自适应 */
        margin: 0 auto 24px auto; /* 图片居中并增加与下方内容的间距 */
    }


    .reward-redeemed-container {
        max-width: 100%; /* 在小屏幕下，使容器宽度填满整个屏幕 */
        width: 100%;
        height: 425px; /* 保持图片的宽高比 */
        text-align: center;
        margin-top: 48px; /* 调整与图片的距离 */
    }

    .redeemed-title-container {
        width: 100%;
        height: 34px;
        margin-bottom: 12px; /* 调整与下方文字的距离 */
    }

    .redeemed-title {
        font-size: 20px; /* 调整标题字体大小，使其适应小屏幕 */
        line-height: 34px;
        font-weight: 500;
    }

    .redeemed-info-container {
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
        padding: 0;
        height: 100%; 
        max-height: 100px;
      
    }

    .redeemed-button-container {
        margin-top: 25px; /* 调整与上方文字的距离 */
    }

    .redeemed-button {
        width: 100%; /* 使按钮填满整个容器宽度 */
        max-width: 326px; /* 设定一个最大宽度 */
        margin: 0 auto; /* 居中对齐 */
    }
}


    
