.recipe-page-ingredient-checkbox-component{
	display: flex;
	gap: 12px;
	height: 22px;
	align-items: center;
}

.recipe-page-ingredient-checkbox{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 20px;
	height: 20px;
	box-sizing: border-box;
	border: 1px #D0D5DD solid;
	border-radius: 4px;
}
.recipe-page-ingredient-checkbox:hover{
	background-color: #FEF0C7;
}
.recipe-page-ingredient-checkbox:active{
	background-color: white;
	border: 1px #FEC84B solid;
}
.recipe-page-ingredient-checkbox-active{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 20px;
	height: 20px;
	box-sizing: border-box;
	border-radius: 4px;
	background-color: #FFFAEB;
	border: 1px #DC6803 solid;
}
.recipe-page-ingredient-checkbox-active:hover{
	background-color: #FEF0C7;
}
.recipe-page-ingredient-checkbox-active:active{
	background-color: #FFFAEB;
}
.recipe-page-ingredient-checkbox-component p{
	font-family: 'Inter';
	font-size: 14px;
	font-weight: 500;
	line-height: 22px;
	letter-spacing: 0.02em;
	color: #344054;
}

.recipe-page-ingredient-checkbox-component img{
	width: 14px;
	height: 14px;
}

.recipe-page-ingredient-checkbox-component img{
	width: 6px;
	height: 6px;
}
