.application-form{
	display: 'block';
	margin-top: 30px;
	width: 350px;
}

.application-form input{
	width: 100%;
    border: white;
    height: 30px;
    margin-bottom: 6px;
    background-color: transparent;
    color: #101010;
    -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.16);
}

.application-form input[type="file" i]{
	line-height: 30px;
}

.application-form button{
    display: inline-block;
    padding: 6px 10px;
    background-color: #4b208c;
    color: #ffffff;
    border-radius: 0px;
    margin-top: 15px;
}

input, button, select, optgroup, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

.valid-msg{
	width: 350px;
	background-color: rgb(34 197 94);
	color: white;
	font-size: 24px;
	text-align: center;
	font-weight: bold;
	padding: 30px;
}

.invalid-msg{
	width: 350px;
	background-color: rgb(239 68 68);
	color: white;
	font-size: 24px;
	text-align: center;
	font-weight: bold;
	padding: 30px;
}

.loader{
	margin-top: 10px;
	width: 16px;
	height: 16px;

	border: 8px solid #e0e0e0;
	border-top: 8px solid blue;
	border-radius: 50%;
	animation: spin 2s linear infinite;
}

.hidden{
	display: none !important;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

