.recipe-page-ingredients{
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 20px;
}
.recipe-page-ingredients-header{
	display: flex;
	width: 100%;
	align-items: center;
	gap: 8px;
}
.recipe-page-ingredients-header-header{
	display: flex;
}
.recipe-page-ingredients-selections{
	margin-left: auto;
	display: flex; 
	gap: 20px;
}
.recipe-page-ingredients-header-serving-count{
	font-family: 'Inter';
	font-size: 16px;
	font-weight: 400;
	line-height: 26px;
	letter-spacing: 0.02em;
	color: #475467;
}

.recipe-page-ingredients-container{
	display: grid;
	grid-template-columns: auto auto;
	gap: 20px;
}

.recipe-page-ingredients-cart{
	width: 100%;
	display: flex;
	justify-content: end;
}


@media(max-width: 767px){
	.recipe-page-ingredients-container{
		grid-template-columns: auto;
	}
	.recipe-page-ingredients-cart>div{
		width: 100%;
	}
	.recipe-page-ingredients-header-header{
		width: 100%;
	}
	.recipe-page-ingredients-header-serving-count{
		display: none;
	}
	.recipe-page-ingredients-header{
		flex-direction: column;
		align-items: start;
		gap: 19px;
	}
	.recipe-page-ingredients-selections{
		width: 100%;
	}
	.recipe-page-ingredients-unselectAll{
		margin-left: auto;
	}
}


