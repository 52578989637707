/* CircularLoader.css */
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75vh;
}

.spinner {
  border: 8px solid #f9fafb; /* Light blue background */
  border-top: 8px solid #fec84b; /* Blue color for the spinning part */
  border-radius: 50%;
  width: 200px;
  height: 200px;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
