.home-search-component{
	box-sizing: border-box;
	width: 100%;
	display: block;
}
.home-search{
	display: flex;
	height: 76px;
	width: 100%;
	padding: 16px 32px;
	box-sizing: border-box;
	gap: 12px;
	min-width: 0;
	flex-shrink: 0;
	overflow: scroll;
    scrollbar-width: none; /* Firefox */
}

.home-search-bar-container{
	display: flex;
	flex: 1 1 0;
	height: 44px;
	border-radius: 8px;
	border: 1px #D0D5DD solid;
	padding: 4px 16px;
	padding-right: 8px;
	box-sizing: border-box;

	align-items: center;
	overflow: scroll;
    scrollbar-width: none; /* Firefox */
	gap: 12px;
}

.home-search-bar-sm:active{
	border: none;
}
.home-search-bar-sm:focus{
	border: none;
	outline: none;
}
.home-search-bar-sm{
	border: none;
	background: none;
	flex: 1;

	font-family: 'Inter';
	font-size: 16px;
	font-weight: 400;
	line-height: 26px;
	letter-spacing: 0.02em;
	text-align: left;

	color: #101828;
	display: none;
}

.home-search-bar:active{
	border: none;
}
.home-search-bar:focus{
	border: none;
	outline: none;
}
.home-search-bar{
	border: none;
	background: none;
	flex: 1;

	font-family: 'Inter';
	font-size: 16px;
	font-weight: 400;
	line-height: 26px;
	letter-spacing: 0.02em;
	text-align: left;

	color: #101828;
}

.home-search-bar::placeholder{
	color: #98A2B3;
}

.home-search-bar-icon-container{
	display: flex;
	align-items: center;
	justify-content: center;
}
.home-search-bar-icon{
	height: 20px;
	width: 20px;
	cursor: pointer;
}

.home-search-filter-sm{
	display: none;
}

.home-search-select-sm{
	height: 76px;
	box-sizing: border-box;
	border-top: 1px #EAECF0 solid;
	padding: 16px 20px;
	display: flex;
	width: 100%;
	display: none;
}

.home-search-filter-card{
	padding: 16px;
	background: white;
	display: flex;
	flex-direction: column;
	width: 410px;
	height: 282px;
	box-sizing: border-box;
	position: absolute;
	right: 32px;
	border-radius: 12px;
	box-shadow: 0px 4px 9px 0px #00000080;
	z-index: 2;
}

.home-search-filter-img-container{
	width: 100%;
	display: flex;
	justify-content: end;
}
.home-search-filter-img-container img{
	cursor: pointer;
	width: 28px;
	height: 28px;
}

.home-search-filter-card-header{
	margin-top: 16px;
	display: flex;
	align-items: center;
}
.home-search-filter-card-title{
	flex: 1;
	font-family: 'Inter';
	font-size: 20px;
	font-weight: 600;
	line-height: 24px;
	color: #1D2939;
}
.home-search-filter-card-reset{
	cursor: pointer;
	font-family: 'Inter';
	font-size: 14px;
	font-weight: 500;
	line-height: 22px;
	letter-spacing: 0.02em;
	text-align: left;
	color: #F79009;
}

.home-search-filter-card-setting{
	margin-top: 32px;
}
.home-search-filter-description{
	font-family: 'Inter';
	font-size: 14px;
	font-weight: 500;
	line-height: 22px;
	letter-spacing: 0.02em;
	text-align: left;
	color: #344054;
}

.home-search-filter-card-options div{
	flex: 1;
}
.home-search-filter-card-options{
	margin-top: 8px;
	display: flex;
	height: 44px;
	width: 100%;
	background-color: #F9FAFB;
	border-radius: 100px;
}

.home-search-filter-confirm{
	margin-top: 32px;
	width: 100%;
}
.home-search-dropdown-container{
	position: absolute;
	left: 538px;
	right: 233px;
	top: 141px;
}

.flex-1{
	flex: 1;
}

@media(max-width: 1024px){
	.home-search-dropdown-container{
		left: 216px;
		right: 233px;
		top: 116px;
	}
}

@media(max-width: 767px){
	.home-search-select{
		display: none;
	}
	.home-search-select-sm{
		display: flex;
	}
	.home-search-bar, .home-search-filter, .home-search-search{
		display: none;
	}
	.home-search-bar-sm, .home-search-filter-sm{
		display: flex;
		align-items: center;
	}
	.home-search-filter-card{
		width: 100%;
		height: auto;
		border-radius: 0;
		bottom: 0;
		top: 124px;
		right: 0;
		box-shadow: none;
		border-top: 1px #EAECF0 solid;
	}
	.home-search-filter-img-container{
		display: none;
	}
	.home-search-filter-card-setting{
		flex: 1;
	}
	.home-search-dropdown-container{
		position: absolute;
		left: 0;
		right: 0;
		top: 200px;
	}
}

.hidden{
	display: none;
}
