.home-page-recipes-container{
	background-color: #F2F4F7;
	padding: 32px;
	padding-top: 16px;
	display: grid;
	gap: 20px;
	grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
}

@media (max-width: 767px){
	.home-page-recipes-container{
		grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
		gap: 12px;
		padding: 12px 14px;
	}
}

