body {
	margin: 0;
	min-height: 100vh;
}

.about-us-container{
	position: static !important;
}

.container-wrapper{
	padding-left: 320px;
	padding-top: 77px;
}

.page{
	display: flex;
	justify-content: center;
	padding: 80px;
	flex-direction: column;
	align-items: center;
	gap: 80px;
	align-self: stretch;

	background: var(--Gray-50, #F9FAFB);
}

.about-us-caption{
	display: flex;
	/* width: 960px; */
	width: 100%;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: var(--Padding-Base, 20px);
}

.about-us-caption-title{
	align-self: stretch;

	color: var(--Primary-600, #DC6803);
	text-align: center;

	/* H1/Bold */
	font-family: Inter;
	font-size: 48px;
	font-style: normal;
	font-weight: 700;
	line-height: 60px; /* 125% */
	letter-spacing: -0.96px;
}
.about-us-caption-description{
	/* width: 819px; */
	width: 100%;

	color: var(--Gray-700, #344054);
	text-align: center;

	/* H5/Medium */
	font-family: Inter;
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 34px; /* 170% */
	letter-spacing: 0.4px;
}

.about-us-cuisine,
.about-us-mobile-phone{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.about-us-cuisine-image{
	width: 65%;
	/* width: 720px; */
	height: auto;
	border-radius: 12px;
}

.about-us-instruction{
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;
	align-self: stretch;
}

.about-us-instruction-title{
	align-self: stretch;

	color: var(--Gray-700, #344054);
	text-align: center;

	/* H3/Medium */
	font-family: Inter;
	font-size: 30px;
	font-style: normal;
	font-weight: 500;
	line-height: 50px; /* 166.667% */
	letter-spacing: 0.6px;
}

.about-us-instruction-description{
	align-self: stretch;

	color: var(--Gray-700, #344054);
	text-align: center;

	/* H6/Regular */
	font-family: Inter;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 28px; /* 155.556% */
	letter-spacing: 0.36px;
}

.about-us-mobile-phone-image{
	width: 50%;
	/* width: 600px; */
	height: auto;
	border-radius: 12px;
}

.about-us-feature{
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 36px;
	align-self: stretch;
}

.about-us-feature-box{
	display: flex;
	padding: 20px;
	flex-direction: column;
	align-items: flex-start;
	gap: 12px;
	flex: 1 0 0;

	border-radius: 12px;
	background: var(--Base-White, #FFF);
}

.about-us-feature-box-title-wrapper{
	display: flex;
	align-items: center;
	gap: 12px;
}

.about-us-feature-box-title{
	color: var(--Gray-700, #344054);

	/* H5/Medium */
	font-family: Inter;
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 34px; /* 170% */
	letter-spacing: 0.4px;
}

.about-us-feature-box-description{
	align-self: stretch;

	color: var(--Gray-700, #344054);

	/* P1/Regular */
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 26px; /* 162.5% */
	letter-spacing: 0.32px;
}

.about-us-search-button{
	display: flex;
	height: 44px;
	min-width: 80px;
	padding: 0px 16px;
	justify-content: center;
	align-items: center;
	gap: 8px;

	border-radius: 100px;
	border: 1px solid var(--Primary-300, #FEC84B);
	background: var(--Base-White, #FFF);

	/* Shadow/xs */
	box-shadow: 0px 1px 2px 0px rgba(24, 39, 75, 0.08);
}

.about-us-search-button:hover{
	background-color: #FEF2D6;
}

.about-us-search-button-name{
	color: var(--Primary-600, #DC6803);

	/* P2/Medium */
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 22px; /* 157.143% */
	letter-spacing: 0.28px;
}

.about-us-news{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 48px;
	align-self: stretch;
	width: 70%;
    margin: 0 auto;   
}

.about-us-news-title{
	align-self: stretch;

	color: var(--Gray-700, #344054);
	text-align: center;

	/* H3/Medium */
	font-family: Inter;
	font-size: 30px;
	font-style: normal;
	font-weight: 500;
	line-height: 50px; /* 166.667% */
	letter-spacing: 0.6px;
}

.about-us-news-container{
	display: flex;
	align-items: center;
	gap: 48px;
	align-self: stretch;
}

.about-us-news-container-box{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	flex: 1 0 0;

	border-radius: 12px;
	background: var(--Base-White, #FFF);
}

.about-us-news-container-box-image{
	width: 100%;
	height: auto;
	object-fit: cover;
	/* height: 300px; */
	align-self: stretch;

	border-radius: 12px 12px 0px 0px;
}

.about-us-news-container-box-description-wrapper{
	display: flex;
	padding: 8px 12px;
	flex-direction: column;
	align-items: flex-end;
	gap: 4px;
	align-self: stretch;
}

.about-us-news-container-box-description-content{
	height: 56px;
	align-self: stretch;

	color: var(--Gray-700, #344054);

	/* H6/Medium */
	font-family: Inter;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 28px; /* 155.556% */
	letter-spacing: 0.36px;
}

.about-us-news-container-box-description-date{
	align-self: stretch;

	color: var(--Gray-700, #344054);
	text-align: right;

	/* P2/Regular */
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px; /* 157.143% */
	letter-spacing: 0.28px;
}

.about-us-sponsorship{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 48px;
	align-self: stretch;
}

.about-us-sponsorship-title{
	align-self: stretch;

	color: var(--Gray-700, #344054);
	text-align: center;

	/* H3/Medium */
	font-family: Inter;
	font-size: 30px;
	font-style: normal;
	font-weight: 500;
	line-height: 50px; /* 166.667% */
	letter-spacing: 0.6px;
}

.about-us-sponsorship-logo-wrapper{
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 80px;
	align-self: stretch;
}

.about-us-sponsorship-logo{
	width: 20%;
	height: auto;
	/* height: 100px; */
}

@media(max-width: 1024px){
	.container-wrapper{
		padding-left: 0;
		padding-top: 35px;
		padding-bottom: 70px;
	}
	.page{
		padding: 80px 48px;
	}
}

@media(max-width: 768px){ 
	.container-wrapper {
		padding-top: 45px;
		padding-bottom: 90px;
	}

	.page {
		padding: 24px;
		gap: 40px;
	}

	.about-us-caption {
		gap: 16px;
	}

	.about-us-caption-title {
		font-size: 25px;
		line-height: 44px;
	}

	.about-us-caption-description {
		font-size: 16px;
		line-height: 28px;
	}

	.about-us-cuisine-image{
		width: 80%;
	}

	.about-us-instruction {
		gap: 24px;
	}

	.about-us-instruction-title {
		font-size: 24px;
		line-height: 36px;
	}

	.about-us-instruction-description {
		font-size: 16px;
		line-height: 24px;
	}

	.about-us-mobile-phone-image{
		width: 70%;
	}

	.about-us-feature {
		flex-direction: column;
		gap: 24px;
	}

	.about-us-feature-box {
		padding: 16px;
		gap: 8px;
	}

	.about-us-feature-box-title {
		font-size: 18px;
		line-height: 28px;
	}

	.about-us-feature-box-description {
		font-size: 14px;
		line-height: 22px;
	}

	.about-us-search-button {
		height: 40px;
		padding: 0 12px;
	}

	.about-us-search-button-name {
		font-size: 12px;
		line-height: 20px;
	}

	.about-us-news {
		gap: 32px;
	}

	.about-us-news-title {
		font-size: 24px;
		line-height: 36px;
	}

	.about-us-news-container {
		flex-direction: column;
		gap: 32px;
	}

	.about-us-news-container-box-description-content {
		font-size: 16px;
		line-height: 24px;
	}

	.about-us-news-container-box-description-date {
		font-size: 12px;
		line-height: 20px;
	}

	.about-us-sponsorship {
		gap: 32px;
	}

	.about-us-sponsorship-title {
		font-size: 24px;
		line-height: 36px;
	}

	.about-us-sponsorship-logo-wrapper {
		gap: 40px;
	}

	.about-us-sponsorship-logo{
		width: 40%;
		height: auto;
	}
}
