
.privacy-policy-box p {
    display: inline;
  }

.read-more-link {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    font: inherit;
    color: inherit;
    cursor: pointer;
    outline: inherit;
    color: rgba(247, 144, 9, 1);
}

.privacy-policy-link {  
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    text-decoration: none;
    color: rgba(247, 144, 9, 1);
    
}

.policy-box {
    margin: 24px;
    gap: 20px;
}

.button-box {
    display: flex;
    justify-content: flex-end;
    gap: 10px; 
    margin-top: 20px;
}

/* smaller device */
@media screen and (max-width: 767px) {
    /* button */
    .button-box {
        justify-content: left;
    }

    .button-box > button {
        flex: 1;
    }
}
