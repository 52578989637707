.my-recipe-card{
	cursor: pointer;
	padding: 12px;
	border-radius: 24px;
	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
	background-color: white;
	height: 318px;
	transition: all 0.2s;
}

.my-recipe-card:hover{
	background-color: #EAECF0;
}

.my-recipe-card-image-container{
	overflow: hidden;
	width: 100%;
	height: 246px;
	align-items: center;
	display: flex;
	border-radius: 20px;
}

.my-recipe-card-image{
	border-radius: 20px;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.my-recipe-card-details{
	flex: 1;
	white-space: nowrap;
	min-width: 0;
}

.my-recipe-card-recipe-name{
	text-overflow: ellipsis;
	overflow: hidden;

	font-family: 'Inter';
	font-size: 16px;
	line-height: 26px;
	font-weight: 500;
	color: #475467;
}

.my-recipe-card-recipe-details{
	margin-top: 8px;
	display: flex;
	gap: 5px;
	align-items: center;
}

.my-recipe-card-bottom{
	margin-top: 8px;
	padding: 0px 8px;
	gap: 20px;
	display: flex;
}

.my-recipe-card-edit-img{
	align-self: flex-end;
}

.my-recipe-card-draft-container{
	margin-left: -80px;
	margin-top: 202px;
	border: 1px #FEC848 solid;
	height: 34px;
	border-radius: 8px;
	padding: 0 12px;
	background-color: #FFFAEB;
	display: flex;
	align-items: center;
}
.my-recipe-card-draft{
	font-family: 'Inter';
	font-weight: 500;
	font-size: 16px;
	line-height: 26px;
	color: #B5470B;

	letter-spacing: 0.02em;

}

@media (max-width: 767px){
	.my-recipe-card{
		padding: 12px;
		height: 201px;
		border-radius: 15px;
	}
	.my-recipe-card-image-container{
		border-radius: 20px;
		height: 133px;
	}
	.my-recipe-card-recipe-name{
		font-size: 14px;
		line-height: 22px;
		color: #344054;
	}
	.my-recipe-card-recipe-details{
		gap: 10px;
	}
	.my-recipe-card-edit-img{
		top: -150px;
		position: relative;
	}
	.my-recipe-card-draft-container{
		margin-top: 96px;
		margin-left: -60px;
		padding: 0 8px;
		height: 24px;
	}

	.my-recipe-card-draft{
		font-family: 'Inter';
		font-weight: 500;
		font-size: 14px;
		line-height: 22px;
		color: #B5470B;
		letter-spacing: 0.02em;
	}

}


