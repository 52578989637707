.saved-recipe-card{
	cursor: pointer;
	height: 318px;
}

.saved-recipe-card-image-container{
	overflow: hidden;
	width: 100%;
	height: 254px;
	align-items: center;
	display: flex;
	border-radius: 24px;
}
.saved-recipe-card-image-cover{
	width: 100%;
	height: 100%;
	margin-left: -100%;
	transition: all 0.2s;
}

.saved-recipe-card:hover .saved-recipe-card-image-cover{
	background-color: #00000033;
}

.saved-recipe-card-image{
	width: 100%;
}

.saved-recipe-card-collection-name{
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;

	margin-top: 8px;
	font-size: 16px;
	font-family: 'Inter';
	font-weight: 500;
	line-height: 26px;
	color: #344054;
}

.saved-recipe-card-collection-count{
	margin-top: 8px;
	font-size: 14px;
	font-family: 'Inter';
	font-weight: 400;
	line-height: 22px;
	color: #667085;
}

@media (max-width: 767px){
	.saved-recipe-card{
		height: 170px;
	}
	.saved-recipe-card-image-container{
		height: 112px;
	}
	.saved-recipe-card-collection-name{
		font-size: 14px;
		line-height: 22px;
		height: 22px;
	}
	.saved-recipe-card-collection-count{
		font-size: 12px;
		line-height: 20px;
		height: 20px;
	}
}


