.cart-item-list{
	display: flex; 
	flex-direction: column;
	gap: 12px;
}

.cart-item-list-title{
	font-family: 'Inter';
	font-size: 24px;
	font-weight: 500;
	line-height: 40px;
	letter-spacing: 0.02em;
	text-align: left;
}

.cart-item-list-total{
	display: flex;
	padding: 12px 16px;
	justify-content: center;
}

.cart-item-list-total-text{
	font-family: Inter;
	font-size: 16px;
	font-weight: 500;
	line-height: 26px;
	letter-spacing: 0.02em;
	text-align: left;
	color: #344054;
	width: 100%;
}

.cart-item-list-total-price{
	font-family: Inter;
	font-size: 16px;
	font-weight: 500;
	line-height: 26px;
	letter-spacing: 0.02em;
	text-align: left;
	color: #344054;
}

.cart-item-list-type{
	width: 600px;
	display: flex;
	border-radius: 999px;
	background-color: #F9FAFB;
}
.cart-item-list-type p{
	text-align: center;
}
.cart-item-list-type div{
	width: 100%;
}

.cart-item-list-recipe{
	display: flex;
	padding: 0 16px;
	border-bottom: 1px solid #EAECF0;
	width: 100%;
	height: 44px;
	overflow: auto;
	scrollbar-width: none;
	box-sizing: border-box;
}

.cart-item-recipe{
	flex-shrink: 0;
}

.cart-item-list-empty-text{
	width: 100%;
	color: #6C757D;
	font-family: Inter;
	font-size: 16px;
	font-weight: 400;
	line-height: 26px;
	letter-spacing: 0.02em;
	text-align: center;
}

.cart-item-list-placeholder{
  padding: 10px;
  color: #F79009;
  width: 100%;
  display: flex;
  box-sizing: border-box;
  margin-top: 20px;
  margin-bottom: 30px;
}

.cart-item-list-placeholder p{
  font-family: 'Inter';
  width: 100%;
  text-align: center;
  font-size: 36px;
}

@media (max-width: 1024px){
	.cart-item-list-type{
		width: 100%;
	}
	.cart-item-list-title{
		font-size: 18px;
		line-height: 28px;
	}

	.cart-item-list-placeholder p{
		font-size: 24px;
	}
}


