.recipe-page-cookwares{
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.recipe-page-cookwares-detail{
	font-family: Inter;
	font-size: 16px;
	font-weight: 400;
	line-height: 26px;
	letter-spacing: 0.02em;
	color: #495057;
}

.recipe-page-cookwares-header{
	display: flex;
}



