.primary-text-wrapper{
	cursor: pointer;
	padding: 0 16px;
	display: flex;
	gap: 8px;
	border-radius: 999px;
	background-color: #F79009;
	height: 44px;
	align-items: center;
	box-shadow: 0px 1px 2px 0px #18274B14;
	display: flex;
	justify-content: center;
}
.primary-text-wrapper:hover{
	background-color: #DC6803;
}
.primary-text{
	font-family: Inter;
	font-size: 14px;
	font-weight: 500;
	line-height: 22px;
	letter-spacing: 0.02em;
	text-align: left;
	color: white;
}
.secondary-icon{
	width: 20px;
	height: 20px;
	padding: 11px;
	border-radius: 999px;
	border: 1px #D0D5DD solid;
	box-shadow: 0px 1px 2px 0px #18274B14;
	background-color: white;
	cursor: pointer;
}

.secondary-icon:hover{
	background-color: #F9FAFB;
}

.secondary-text-icon{
	width: 20px;
	height: 20px;
}

.secondary-text{
	font-family: 'Inter';
	font-size: 14px;
	font-weight: 500;
	line-height: 22px;
	letter-spacing: 0.02em;
	text-align: center;
	cursor: pointer;
}


.secondary-text-wrapper{
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px 16px;
	border-radius: 100px;
	border: 1px #D0D5DD solid;
	box-shadow: 0px 1px 2px 0px #18274B14;
	background-color: white;
	cursor: pointer;
	display: flex;
	gap: 8px;
}
.secondary-text-wrapper:hover{
	background-color: #F9FAFB;

}

.secondary-prime-wrapper{
	cursor: pointer;
	height: 42px;
	border: 1px #FEC848 solid;
	padding: 0 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 100px;
	gap: 8px;
	border-radius: 100px;
}
.secondary-prime-text{
	cursor: pointer;
	border: none;
	font-family: 'Inter';
	font-size: 14px;
	font-weight: 500;
	line-height: 22px;
	letter-spacing: 0.02em;
	color: #DC6803;
	text-align: center;
}

.secondary-prime-icon{
	width: 20px;
	height: 20px;
	cursor: pointer;
	filter: brightness(0) saturate(100%) invert(31%) sepia(87%) saturate(1639%) hue-rotate(18deg) brightness(108%) contrast(98%);
	margin: auto;
}

.secondary-prime-wrapper:hover{
	background-color: #FFFAEB;
}
.secondary-prime-wrapper:hover .secondary-prime-icon{
	filter: brightness(0) saturate(100%) invert(25%) sepia(95%) saturate(1599%) hue-rotate(8deg) brightness(96%) contrast(94%);
}
.secondary-prime-wrapper:hover .secondary-prime-text{
	color: #B54708;
}

.secondary-prime-wrapper:active .secondary-prime-icon{
	filter: brightness(0) saturate(100%) invert(31%) sepia(87%) saturate(1639%) hue-rotate(18deg) brightness(108%) contrast(98%);
}
.secondary-prime-wrapper:active .secondary-prime-text{
	color: #DC6803;
}


.secondary-prime-wrapper-disabled{
	cursor: not-allowed;
	height: 42px;
	border: 1px #FEDF89 solid;
	padding: 0 16px;
	display: flex;
	gap: 8px;
	align-items: center;
	justify-content: center;
	border-radius: 100px;
	width: fit-content;
}
.secondary-prime-text-disabled{
	cursor: not-allowed;
	border: none;
	font-family: 'Inter';
	font-size: 14px;
	font-weight: 500;
	line-height: 22px;
	letter-spacing: 0.02em;
	color: #FEDF89;
	text-align: center;
}
.secondary-prime-icon-disabled{
	filter: brightness(0) saturate(100%) invert(72%) sepia(70%) saturate(410%) hue-rotate(352deg) brightness(101%) contrast(99%);
}

.xx-large-avatar-container{
	width: 72px;
	height: 72px;
	background-color: #F2F4F7;
	border-radius: 999px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: auto;
}
.xx-large-avatar-avatar{
	width: 72px;
	height: 72px;
	border-radius: 999px;
	object-fit: cover;
}
.xx-large-avatar-default{
	width: 40px;
	height: 40px;
	object-fit: cover;
}
.xx-large-avatar-cam{
	display: none;
	width: 28px;
	height: 23.33px;
	position: absolute;
}
.xx-large-avatar-cover{
	width: 72px;
	height: 72px;
	position: absolute;
	border-radius: 999px;
}
.xx-large-avatar-container:hover .xx-large-avatar-cover{
	background-color: #DC680333;
}
.xx-large-avatar-container:hover .xx-large-avatar-cam{
	display: block;
}

.xx-large-avatar-container:active .xx-large-avatar-cover{
	background: none;
	outline: 4px solid var(--Primary-100, #FEF0C7)
}
.xx-large-avatar-container:active .xx-large-avatar-cam{
	display: none;
}

.s-avatar-container{
	width: 40px;
	height: 40px;
	background-color: #F2F4F7;
	border-radius: 999px;
	display: flex;
	align-items: center;
	cursor: pointer;
}
.s-avatar-avatar{
	width: 40px;
	height: 40px;
	border-radius: 999px;
	object-fit: cover;
}
.s-avatar-cover{
	width: 40px;
	height: 40px;
	position: absolute;
	border-radius: 999px;
}
.s-avatar-container:hover .s-avatar-cover{
	background-color: #DC680333;
}

.s-avatar-container:active .s-avatar-cover{
	background: none;
	outline: 4px solid var(--Primary-100, #FEF0C7)
}

.x-large-avatar-container{
	width: 56px;
	height: 56px;
	background-color: #F2F4F7;
	border-radius: 999px;
	display: flex;
	align-items: center;
	cursor: pointer;
}
.x-large-avatar-avatar{
	width: 56px;
	height: 56px;
	border-radius: 999px;
	object-fit: cover;
}
.x-large-avatar-cover{
	width: 56px;
	height: 56px;
	position: absolute;
	border-radius: 999px;
}
.x-large-avatar-container:hover .x-large-avatar-cover{
	background-color: #DC680333;
}

.x-large-avatar-container:active .x-large-avatar-cover{
	background: none;
	outline: 4px solid var(--Primary-100, #FEF0C7)
}

.tertiary-text-container{
	cursor: pointer;
	border-radius: 100px;
	padding: 11px 16px;
	display: flex;
	gap: 8px;
}

.tertiary-text{
	font-family: 'Inter';
	font-size: 14px;
	font-weight: 500;
	line-height: 22px;
	letter-spacing: 0.02em;
	text-align: center;
	color: #667085;
	margin: auto;
}


.tertiary-text-container:hover{
	background-color: #FEF0C7;
	box-shadow: 0px 1px 2px 0px #18274B14;
}

.tertiary-text-container:hover .tertiary-text{
	color: #B54708;
}

.tertiary-text-container:hover .prime-tertiary-text{
	color: #B54708;
}


.tertiary-text-active{
	font-family: 'Inter';
	font-size: 14px;
	font-weight: 500;
	line-height: 22px;
	letter-spacing: 0.02em;
	text-align: center;
	color: #344054;
	margin: auto;
}

.s-tertiary-icon-container{
	width: 28px;
	height: 28px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 999px;
}
.s-tertiary-icon-container:hover{
	background-color: #F2F4F7;
}

.s-tertiary-text-container{
	cursor: pointer;
	border-radius: 100px;
	padding: 3px 8px;
	display: flex;
	gap: 8px;
}
.s-tertiary-text-container-active{
	cursor: pointer;
	border-radius: 100px;
	padding: 3px 8px;
	display: flex;
	gap: 8px;
	background-color: #F2F4F7;
	box-shadow: 0px 1px 2px 0px #18274B14;
}

.s-tertiary-text-container:hover{
	background-color: #F2F4F7;
	box-shadow: 0px 1px 2px 0px #18274B14;
}

.s-tertiary-text-container:hover .tertiary-text{
	color: #344054;
}

.s-prime-tertiary-text-container-disabled{
	cursor: not-allowed;
	border-radius: 100px;
	padding: 3px 8px;
	display: flex;
	gap: 8px;
}
.s-prime-tertiary-text-container{
	cursor: pointer;
	border-radius: 100px;
	padding: 3px 8px;
	display: flex;
	gap: 8px;
}
.s-prime-tertiary-text-container-active{
	cursor: pointer;
	border-radius: 100px;
	padding: 3px 8px;
	display: flex;
	gap: 8px;
	background-color: #F2F4F7;
	box-shadow: 0px 1px 2px 0px #18274B14;
}

.s-prime-tertiary-text-container:hover{
	background-color: #FEF0C7;
	box-shadow: 0px 1px 2px 0px #18274B14;
}

.s-prime-tertiary-text-container:hover .prime-tertiary-text{
	color: #B54708;
}

.prime-tertiary-text{
	font-family: 'Inter';
	font-size: 14px;
	font-weight: 500;
	line-height: 22px;
	letter-spacing: 0.02em;
	text-align: left;
	color: #F79009;
}

.prime-tertiary-text-disabled{
	font-family: 'Inter';
	font-size: 14px;
	font-weight: 500;
	line-height: 22px;
	letter-spacing: 0.02em;
	text-align: left;
	color: #FEC84B;
}

.l-tertiary-container{
	width: fit-content;
	height: 44px;
	display: flex;
	align-items: center;
	gap: 8px;
	justify-content: center;
	border-radius: 999px;
	box-sizing: border-box;
	padding: 0px 16px;
}
.l-tertiary-icon{
	width: 20px;
	height: 20px;
	filter: invert(48%) sepia(11%) saturate(793%) hue-rotate(183deg) brightness(87%) contrast(86%);
}
.l-tertiary-container:hover{
	background-color: #F2F4F7;
}
.l-tertiary-container:hover .l-tertiary-icon{
	filter: invert(24%) sepia(12%) saturate(1177%) hue-rotate(179deg) brightness(93%) contrast(94%);
}

.tab-button-container{
	cursor: pointer;
	padding: 11px 16px;
	padding-bottom: 10px;
}
.tab-button-text{
	font-family: 'Inter';
	font-weight: 500;
	font-size: 14px;
	line-height: 22px;
	color: #667085;
	letter-spacing: 0.02em;
	transition: all 0.2s;
}
.tab-button-container:hover .tab-button-text{
	color: #DC6803;
}

.tab-button-active-container{
	cursor: pointer;
	padding: 11px 16px;

	border-bottom: 2px #DC6803 solid;
	padding-bottom: 8px;
}
.tab-button-active-text{
	font-family: 'Inter';
	font-weight: 500;
	font-size: 14px;
	line-height: 22px;
	color: #DC6803;
	letter-spacing: 0.02em;
}



.tab-button-secondary-container{
	cursor: pointer;
	padding: 11px 16px;
	border-radius: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.tab-button-secondary-text{
	font-family: 'Inter';
	font-weight: 500;
	font-size: 14px;
	line-height: 22px;
	color: #667085;
	letter-spacing: 0.02em;
	transition: all 0.2s;
}
.tab-button-secondary-container:hover{
	background-color: #F2F4F7;
}

.tab-button-secondary-active-container{
	cursor: pointer;
	padding: 11px 16px;
	background-color: #FEF0C7;
	border-radius: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.tab-button-secondary-active-text{
	font-family: 'Inter';
	font-weight: 500;
	font-size: 14px;
	line-height: 22px;
	color: #DC6803;
	letter-spacing: 0.02em;
}
.tab-button-secondary-active-container:hover{
	background-color: #FEDF89;
}

.chip-container{
	cursor: pointer;
	display: flex;
	align-items: center;
	padding: 4px 12px;
	height: 22px;
	background-color: #F2F4F7;
	border-radius: 30px;
	gap: 8px;
	transition: all 0.2s;
	width: fit-content;
}
.recipe-page-header-stats>.chip-container{
	padding: 8px 20px;
	box-sizing: border-box;
	height: 40px;
}
.recipe-page-header-stats>.components-saved-recipe>.chip-container{
	padding: 8px 20px;
	box-sizing: border-box;
	height: 40px;
}
.chip-container:hover{
	background-color: #EAECF0;
}
.chip-container:active{
	background-color: #D0D5DD;
}

.chip-icon{
	width: 20px;
	height: 20px;
}

.chip-stat{
	font-family: 'Inter';
	font-weight: 500;
	font-size: 14px;
	line-height: 22px;
	color: #344054;
	letter-spacing: 0.02em;
}


.select{
	height: 44px;
	padding: 9px 16px;
	padding-right: 8px;
	border-radius: 8px;
	border: none;
	border-right: 12px white solid;
	outline: 1px #D0D5DD solid;

	font-family: 'Inter';
	font-size: 16px;
	font-weight: 400;
	line-height: 26px;
	letter-spacing: 0.02em;
	text-align: left;

	color: #344054;

	box-shadow: 0px 1px 2px 0px #18274B14;

}

.select:focus{
	outline: 1px #FEC84B solid;
}

.dropdown-container{
	box-shadow: 0px 4px 14px -6px #18274B1F;
	background-color: white;
	padding: 16px;
	display: flex;
	flex-direction: column;
	gap: 16px;
	box-sizing: border-box;
}

.dropdown-item{
	width: 100%;
	box-sizing: border-box;
	padding: 11px 44px 11px 16px;

	font-family: 'Inter';
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
	letter-spacing: 0.02em;
	color: #344054;
}

.dropdown-item:hover{
	background-color: #F9FAFB;
}


.s-gray-tag-container{
	background-color: #F2F4F7;
	border-radius: 8px;
	display: flex;
	height: 26px;
	cursor: pointer;
	gap: 6px;
	padding: 0 8px;
	align-items: center;
}
.s-gray-tag-container:hover{
	background-color: #EAECF0;
}
.s-gray-tag-container:active{
	background-color: #F2F4F7;
	border: 1px #98A2B3;
	box-sizing: border-box;
}

.s-gray-tag-text{
	font-family: 'Inter';
	font-size: 14px;
	font-weight: 500;
	line-height: 22px;
	letter-spacing: 0.02em;
	width: 100%;
	white-space: pre;
}

.s-gray-tag-icon{
	width: 16px;
	height: 16px;
}

.xl-orange-stroke-tag-container{
	height: 36px;
	border-radius: 8px;
	border: 1px #FEC84B solid;
	box-sizing: border-box;
	padding: 0px 12px;
	gap: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #FFFAEB;
	cursor: pointer;
}
.xl-orange-stroke-tag-container:hover{
	background-color: #FEC84B;
}
.xl-orange-stroke-tag-container:active{
	background-color: #FFFAEB;
}
.xl-orange-stroke-tag-text{
	font-family: 'Inter';
	font-size: 16px;
	font-weight: 500;
	line-height: 26px;
	letter-spacing: 0.02em;
	color: #B54708;
	white-space: pre;
	box-sizing: border-box;
	padding: 0;
}

.sm-orange-stroke-tag-container{
	height: 26px;
	border-radius: 8px;
	border: 1px #FEC84B solid;
	box-sizing: border-box;
	padding: 1px 8px;
	gap: 6px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #FFFAEB;
	cursor: pointer;
}
.sm-orange-stroke-tag-container:hover{
	background-color: #FEC84B;
}
.sm-orange-stroke-tag-container:active{
	background-color: #FFFAEB;
}
.sm-orange-stroke-tag-text{
	font-family: 'Inter';
	font-size: 14px;
	font-weight: 500;
	line-height: 22px;
	letter-spacing: 0.02em;
	color: #B54708;
	white-space: pre;
	box-sizing: border-box;
	padding: 0;
}

.quaternary-prime-text{
	cursor: pointer;
	font-family: 'Inter';
	font-size: 14px;
	font-weight: 500;
	line-height: 22px;
	letter-spacing: 0.02em;
	text-align: left;
	color: #F79009;
	white-space: nowrap;
}
.quaternary-prime-text:hover{
	color: #DC6803;
}
.quaternary-prime-text-disabled{
	font-family: 'Inter';
	font-size: 14px;
	font-weight: 500;
	line-height: 22px;
	letter-spacing: 0.02em;
	text-align: left;
	color: #FEC84B;
}

.quaternary-text{
	cursor: pointer;
	font-family: 'Inter';
	font-size: 14px;
	font-weight: 500;
	line-height: 22px;
	letter-spacing: 0.02em;
	text-align: left;
	color: #667085;
}
.quaternary-text:hover{
	color: #344054;
}
.quaternary-text-disabled{
	font-family: 'Inter';
	font-size: 14px;
	font-weight: 500;
	line-height: 22px;
	letter-spacing: 0.02em;
	color: #D0D5DD;
}


@media (max-width: 767px){
	.xx-large-avatar-container{
		width: 56px;
		height: 56px;
	}
	.xx-large-avatar-cover{
		width: 56px;
		height: 56px;
	}
	.xx-large-avatar-avatar{
		width: 56px;
		height: 56px;
	}
	.xx-large-avatar-default{
		width: 34px;
		height: 34px;
	}
	.chip-icon{
		width: 15px;
		height: 15px;
	}
	.chip-stat{
		height: 20px;
		font-size: 12px;
		line-height: 20px;
		font-weight: 500;
	}
	.chip-container{
		padding: 4px 8px;
	}
}


