.home-page-tags-component{
	height: 100px;
	width: 100%;
	background-color: #F2F4F7;
	padding: 32px;
	box-sizing: border-box;
}

.home-page-tags-list{
	width: 100%;
	display: flex;
	gap: 12px;
	overflow: scroll;
    scrollbar-width: none; /* Firefox */
	box-sizing: border-box;
	align-items: center;
}

.home-page-tags-sm{
	display: none;
}

@media(max-width: 767px){
	.home-page-tags-component{
		height: 50px;
		padding: 12px 20px;
	}
	.home-page-tags{
		display: none;
	}
	.home-page-tags-sm{
		display: inline-block;
	}
}


