.user-profile-followers-header{
	display: flex;
	align-items: center;
	gap: 12px;
}

.user-profile-followers-text{
	color: #344054;
	font-family: 'Inter';
	font-weight: 500;
	font-size: 24px;
	line-height: 40px;
}

.user-profile-followers-list{
	margin-top: 48px;
}

.user-profile-followers-item{
	margin-bottom: 20px;
	display: flex;
	align-items: center;
}

.user-profile-followers-details{
	display: flex;
	flex: 1;
	align-items: center;
	gap: 10px;
}
.user-profile-followers-icon{
	width: 56px;
	height: 56px;
	border-radius: 999px;
}

.user-profile-followers-name{
	font-family: 'Inter';
	font-size: 14px;
	font-weight: 500;
	line-height: 22px;
}

.user-profile-followers-following{
}




