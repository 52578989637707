.error-page-container{
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 48px 160px;
	box-sizing: border-box;
	margin-top: 77px;
	min-height: calc(100vh - 77px);
}

.error-page-description{
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 20px;
}

.error-page-text-container{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 8px;
}

.error-page-text-container p{
	font-family: 'Inter';
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
	letter-spacing: 0.02em;
	text-align: center;
	color: #344054;
}

.error-page-text-container h3{
	font-family: 'Inter';
	font-size: 30px;
	font-weight: 500;
	line-height: 50px;
	letter-spacing: 0.02em;
	text-align: center;
	color: #344054;
}

.error-page-button{
	display: flex;
	margin-top: 48px;
}


@media (max-width: 1024px){
	.error-page-container{
		margin-top: 48px;
		padding: 48px;
		padding-bottom: 130px;
		min-height: calc(100vh - 48px);
	}
}

@media (max-width: 767px){
	.error-page-container{
		padding: 24px;
		padding-bottom: 130px;
		margin-top: 48px;
	}
	.error-page-description img{
		width: 50px;
		height: 50px;
	}
	.error-page-button{
		width: 100%;
	}
	.error-page-button div{
		width: 100%;
	}

	.error-page-text-container{
		gap: 12px;
	}

	.error-page-text-container h3{
		font-size: 20px;
		line-height: 34px;
		color: #1D2939;
	}
	.error-page-text-container p{
		font-size: 14px;
		line-height: 22px;
	}
}

