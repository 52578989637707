.cart-checkout{
	position: fixed;
	bottom: 0;
	height: 74px;
	left: 0;
	right: 0;
	box-sizing: border-box;
	padding: 15px 16px;
	display: flex;
	justify-content: end;
}

.cart-checkout-continue{
	width: 139px;
	box-sizing: border-box;
}

.cart-checkout-confirm{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #10182899;
	z-index: 5;
	display: flex;
	align-items: center;
	justify-content: center;
}

.cart-checkout-confirm-text{
	font-family: 'Inter';
	font-size: 18px;
	font-weight: 500;
	line-height: 28px;
	letter-spacing: 0.02em;
	text-align: left;
	color: #101828;
}

.cart-checkout-confirm-card{
	width: 600px;
	height: 144px;
	border-radius: 16px;
	padding: 24px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	gap: 24px;
	background-color: white;
	box-shadow: 0px 8px 22px -6px #18274B1F;
}

.cart-checkout-confirm-buttons{
	justify-content: end;
	display: flex;
	gap: 12px;
}

@media (max-width: 1024px){
	.cart-checkout-continue{
		width: 100%;
	}
	.cart-checkout{
		bottom: 72px;
		background-color: white;
	}
	.cart-checkout-confirm-card{
		width: 343px;
		height: fit-content;
		min-height: 182px;
		padding: 16px;
	}
	.cart-checkout-confirm-buttons{
		display: flex;
		flex-direction: column-reverse;

	}
	.cart-checkout-confirm-text{
		font-size: 16px;
		line-height: 26px;
		text-align: center;
	}
}


