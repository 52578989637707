.components-create-collection{
	padding: 16px;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: #10182899;
	z-index: 5;
	display: flex;
	align-items: center;
	justify-content: center;
}

.components-create-collection-card{
	width: 600px;
	background-color: white;
	border-radius: 16px;
	box-shadow: 0px 8px 22px -6px #18274B1F;
	display: flex;
	padding: 24px;
	gap: 20px;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.components-create-collection-title{
	font-family: 'Inter';
	font-size: 24px;
	font-weight: 500;
	line-height: 40px;
	letter-spacing: 0.02em;
	text-align: center;
	color: #101828;
}

.components-create-collection-name{
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 6px;
}

.components-create-collection-name p{
	font-family: Inter;
	font-size: 14px;
	font-weight: 500;
	line-height: 22px;
	letter-spacing: 0.02em;
	text-align: left;
	color: #344054;
}

.components-create-collection-name input{
	padding: 9px 8px 9px 16px;
	border-radius: 8px;
	border: 1px #D0D5DD solid;
	box-shadow: 0px 1px 2px 0px #18274B14;

	font-family: Inter;
	font-size: 16px;
	font-weight: 400;
	line-height: 26px;
	letter-spacing: 0.02em;
}
.components-create-collection-name input::placeholder{
	color: #98A2B3;
}

.components-create-collection-submit{
	display: flex;
	justify-content: end;
	width: 100%;
	gap: 12px;
}

@media (max-width: 767px){
	.components-create-collection-submit{
		flex-direction: column-reverse;
	}
}


