.recipe-page-recipe-tag-container{
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.recipe-page-tags-header{
	display: flex;
}

.recipe-page-tag-container{
	display: flex;
	gap: 8px;
	column-gap: 12px;
	flex-wrap: wrap;
}


