.cart-dish-list{
}
.cart-dish-list-header{
	display: flex;
	gap: 20px;
	height: 44px;
	align-items: center;
}

.cart-dish-list-title{
	font-family: 'Inter';
	font-size: 24px;
	font-weight: 500;
	line-height: 40px;
	letter-spacing: 0.02em;
	text-align: left;
}

.cart-dish-list-text-more{
	text-align: center;
	font-family: Inter;
	font-size: 14px;
	font-weight: 500;
	line-height: 44px;
	letter-spacing: 0.02em;
	color: #F79009;
	padding: 0 16px;
}

.cart-dish-list-component{
	margin-top: 12px;
	display: flex;
	width: 100%;
	overflow: auto;
	height: 107px;
	gap: 16px;
	align-items: center;
	scrollbar-width: none;
}

.cart-dish-list-empty-text{
	width: 100%;
	color: #6C757D;
	font-family: Inter;
	font-size: 16px;
	font-weight: 400;
	line-height: 26px;
	letter-spacing: 0.02em;
	text-align: center;
}

@media (max-width: 1024px){
	.cart-dish-list-title{
		font-size: 18px;
		line-height: 18px;
	}
}


