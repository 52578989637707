
.header{
	position: fixed;
	width: 100%;
	height: 73px;
	z-index: 2;
	box-sizing: border-box;
}
.header-nav{
	display: flex;
	align-items: center;
	background-color: #FFFFFF;
	padding: 12px 32px;
	gap: 20px;
	border-bottom: 1px solid #D0D5DD;
}

.header-icon{
	width: 72px;
	height: 48px;
}

.header-options-mobile{
	display: none;
}

.header-options{
	flex: 1;
}

.header-reward, .header-about-us{
	color: #495057;
	padding: 10.5px 17.5px;
	text-decoration: none;
	line-height: 22px;
	font-family: 'Inter';
	font-size: 14px;
	font-weight: 400;
}
.header-reward:hover, .header-about-us:hover, 
.header-sign-out:hover, .header-terms:hover, .header-privacy:hover{
	background-color: #F2F4F7;
	border-radius: 100px;
}

.header-instagram-icon:hover, .header-facebook-icon:hover{
}


.header-user{
	display: flex;
	align-items: center;
	gap: 8px;
}
.header-login, .header-create-recipe, .header-language{
	padding: 12px 16px;
	display: flex;
	text-decoration: none;
	gap: 8px;
}
.header-login:hover{
	box-shadow: 0px 1px 2px 0px #18274B14;
	background-color: #FEF0C7;
	border-radius: 100px;
}

.header-language:hover{
	background-color: #F9FAFB;
}


.header-login-icon, .header-create-recipe-icon, .header-language-icon, .header-user-icon-img{
	width: 20px;
	height: 20px;
}

.header-login-text{
	color: #F79009;
	line-height: 22px;
	font-family: 'Inter';
	font-size: 14px;
	font-weight: 500;
}

.header-create-recipe{
	border: 1px solid #FEC84B;
	padding: 11px 16px;
	border-radius: 999px;
}

.header-create-recipe-text{
	color: #DC6803;
	line-height: 22px;
	font-family: 'Inter';
	font-size: 14px;
	font-weight: 500;
}

.header-login:hover .header-login-icon{
	filter: invert(41%) sepia(91%) saturate(5895%) hue-rotate(23deg) brightness(89%) contrast(94%);
}
.header-login:hover .header-login-text{
	color: #B54708;
}

.header-create-recipe:hover{
	background-color: #FFFAEB;
	color: #B54708;
}
.header-create-recipe:hover .header-create-recipe-icon{
	filter: invert(41%) sepia(91%) saturate(5895%) hue-rotate(23deg) brightness(89%) contrast(94%);
}
.header-create-recipe:hover .header-create-recipe-text{
	color: #B54708;
}

.header-create-recipe:active .header-create-recipe-icon{
	filter: invert(33%) sepia(72%) saturate(2149%) hue-rotate(18deg) brightness(105%) contrast(98%);
}
.header-create-recipe:active .header-create-recipe-text{
	color: #DC6803;
}
.header-language{
	cursor:pointer;
	border: 1px solid #D0D5DD;
	padding: 12px 16px;
	border-radius: 999px;
}
.header-language:hover{
	background-color: #F9FAFB;
}

.header-language-text{
	color: #344054;
	line-height: 22px;
	font-family: 'Inter';
	font-size: 14px;
	font-weight: 500;
}

.header-user-icon-a{
	padding: 0;
}

.header-user-icon-img{
	border-radius: 999px;
	width: 44px;
	height: 44px;
}

.header-hamburger, .header-back{
	width: 20px;
	height: 20px;
	display: none;
}

.header-checkbox{
	width: 20px;
	height: 20px;
	position: absolute;
	left: 20px;
	opacity: 0;
	display: none;
	cursor: pointer;
}

.header-icon-mobile{
	height: 20px;
	display: none;
}

.header-mobile-icons{
	border-radius: 999px;
	width: 44px;
	height: 44px;
	display: flex;
	align-items: center;
	justify-content: center;
	display: none;
}
.header-checkbox:hover ~ .header-mobile-icons{
	background-color: #F2F4F7;
}

.header-bottom{
	position: absolute;
	bottom: 20px;
	left: 32px;
	right: 32px;
	display: none;
}
.header-bottom hr{
	border: 0;
	height: 1px;
	background-color: #e0e0e0;
	width: 100%;
	margin: 8px 0px;
}

.header-sign-out, .header-terms, .header-privacy{
	text-decoration: none;
	display: flex;
	gap: 12px;
	padding: 11px;
}
.header-sign-out p, .header-terms, .header-privacy{
	color: #344054;
	line-height: 22px;
	font-family: 'Inter';
	font-size: 14px;
	font-weight: 400;
}

.header-social-media{
	display: flex;
}
.header-social-media img{
	width: 20px;
	height: 20px;
}
.header-social-media a{
	width: 40px;
	height: 40px;
	display: flex; 
	align-items: center;
	justify-content: center;
	border-radius: 999px;
}

.header-social-media a:hover{
	background-color: #F2F4F7;
	border-radius: 100px;
}


.height-100{
	height: auto;
}

.header-language-float{
	display: flex;
	flex-direction: column;
	position: absolute;
	margin-top: 35px;
	margin-left: -16px;
	background-color: white;
	border-radius: 8px;
	box-shadow: 0px 4px 14px -6px #18274B1F;
	width: 76px;
}
.header-language-float>p{
	padding: 11px 16px 11px 16px;
	font-family: Inter;
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
	letter-spacing: 0.02em;
	text-align: center;
}
.header-language-float>p:hover{
	background-color: #F9FAFB;
}

@media (max-width: 1024px){
	.header-icon-mobile, .header-hamburger, .header-bottom{
		display: block;
	}
	.header-icon-mobile{
		margin: auto;
	}
	.header-create-recipe-text, .header-language-text{
		display: none;
	}
	.header-icon{
		display: none;
	}
	.header-login, .header-user-icon-a{
		display: none;
	}

	.header-mobile-icons{
		display: flex;
	}
	.header-icon-container{
		flex: 1;
	}
	.header-checkbox{
		display: block;
	}


	.header-options{
		display: none;
	}
	.header-options-mobile{
		height: calc(100vh - 88px);
		display: block;
		top: 48px;
		position: absolute;
		padding: 20px 32px;
		z-index: 3;
		left: -100vw;
		right: 100vw;
		transition: all 0.5s ease-in-out;
		background-color: white;
		border-right: 1px solid #D0D5DD;
		bottom: 0;
		opacity: 0;
	}
	.header-about-us, .header-reward{
		padding: 11px;
		display: block;
		width: calc(100%-22px);
		color: #344054;
		font-weight: 400;
		font-family: 'Inter';
		line-height: 22px;
	}

	.header-create-recipe, .header-language{
		border: none;
		padding: 12px;
	}
	.header-language-float{
		width: 44px;
		margin-left: -12px;
	}
	.header-language-float p{
		padding: 11px 0;
	}
	.header-nav{
		padding: 2px 8px;
		padding-bottom: 1px;
	}

	.header-checkbox:checked ~ .header-mobile-icons .header-hamburger{
		display: none;
	}

	.header-checkbox:checked ~ .header-mobile-icons .header-back{
		display: block;
	}

	.header-checkbox:checked ~ .header-options-mobile{
		opacity: 1;
		left: 0;
		right: 0;
	}
}

.hidden{
	display: none;
}

