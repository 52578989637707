.user-profile-update-profile{
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: #10182899;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 5;
	padding: 24px;
	box-sizing: border-box;
}

.user-profile-update-profile-card{
	background-color: white;
	width: 552px;
	height: 764px;
	padding: 24px;

	max-height: 100%;
	box-sizing: border-box;
	box-shadow: 0px 8px 22px -6px #18274B1F;
	border-radius: 16px;

	overflow: scroll;
	scrollbar-width: none;
}

.user-profile-update-profile-card-header{
	width: 100%;
}
.user-profile-update-profile-card-header p{
	font-family: 'Inter';
	font-size: 24px;
	font-weight: 500;
	line-height: 40px;
	letter-spacing: 0.02em;
	text-align: center;

}
.user-profile-update-profile-card-header div{
	margin-top: 20px;
}

.user-profile-form-name-container{
	display: flex;
	flex: 1fr 1fr;
	gap: 12px;
}

.user-profile-form-input-container{
	width: 100%;
	margin-top: 20px;
}

.user-profile-form-text{
	font-family: 'Inter';
	font-size: 14px;
	font-weight: 500;
	line-height: 22px;
	letter-spacing: 0.02em;
	text-align: left;
	color: #344054;
}
.user-profile-form-input{
	margin-top: 6px;
	height: 44px;
	border-radius: 8px;
	outline: 1px #D0D5DD solid;
	border: none;
	width: 100%;
	box-sizing: border-box;

	padding: 9px;
	padding-left: 16px;

	font-family: 'Inter';
	font-size: 16px;
	font-weight: 400;
	line-height: 26px;
	letter-spacing: 0.02em;
	text-align: left;
}

.user-profile-form-textarea{
	margin-top: 6px;

	width: 100%;
	height: 104px;
	padding: 8px 12px;
	box-sizing: border-box;

	border-radius: 8px;
	outline: 1px #D0D5DD solid;
	border: none;
	resize: none;

	font-family: 'Inter';
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
	letter-spacing: 0.02em;
}


.user-profile-form-input-birthday::-webkit-datetime-edit-day-field:focus,
.user-profile-form-input-birthday::-webkit-datetime-edit-month-field:focus,
.user-profile-form-input-birthday::-webkit-datetime-edit-year-field:focus {
	background-color: #F2F4F7;
}

.user-profile-form-submission{
	display: flex;
	margin-top: 20px;
	height: 44px;
	width: 100%;
	justify-content: end;
	align-items: center;
	gap: 8px;
}

.user-profile-form-submission input{
	background: none;
	border: none;
}
.user-profile-form-readonly{
	cursor: not-allowed;
	color: #98A2B3;
}



.user-profile-upload-photo-card{
	width: 600px;
	border-radius: 16px;
	padding: 24px;
	box-sizing: border-box;
	background-color: white;
}
.user-profile-upload-photo-header{
	font-family: 'Inter';
	font-size: 24px;
	font-weight: 500;
	line-height: 40px;
	letter-spacing: 0.02em;
	text-align: center;
	color: #101828;
}

.user-profile-upload-photo-options{
	width: 100%;
	margin: auto;
	display: flex;
	align-items: center;
	justify-content: end;
}
.user-profile-upload-photo-option-icon{
	width: 20px;
	height: 20px;
	padding: 4px;
}

.user-profile-upload-photo-main{
	width: 100%;
	margin-top: 6px;
	height: 256px;
	border: 1px #D0D5DD dotted;
	padding: 16px;
	border-radius: 8px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 12px;
}

.user-profile-upload-photo-description{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.user-profile-upload-photo-description p{
	width: 100%;

	font-family: 'Inter';
	font-size: 14px;
	font-weight: 500;
	line-height: 22px;
	letter-spacing: 0.02em;
	text-align: center;
	color: #98A2B3;

}

.user-profile-uploaded-photo{
	width: 100%;
	height: 100%;
	object-fit: contain; 
	object-position: center; 
}

@media (max-width: 767px){
	.user-profile-update-profile{
		padding: 24px;
	}
	.user-profile-update-profile-card{
		width: 100%;
	}
	.user-profile-update-profile-card-header p{
		font-size: 20px;
		line-height: 34px;
	}
}

.hidden{
	display: none !important;
}


