body {
  margin: 0;
  height: 100%;
}

/* desktop device */
.container-wrapper {
  display: flex;
  position: relative;
  flex-direction: column;
}

.header {
  position: fixed;
  width: 100%;
  z-index: 2;
}

