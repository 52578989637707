.user-collection-content{
	width: 100%;
}


.user-collection-recipes-list{
	margin-top: 20px;
	width: 100%;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 20px; /* Adjust gap between grid items */
}

.user-collection-recipe-card{
	display: inline-block;
}


.user-collection-description{
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.user-collection-name{
	font-family: 'Inter';
	font-size: 30px;
	font-weight: 500;
	line-height: 50px;
	letter-spacing: 0.02em;
	text-align: left;
}
.user-collection-brief{
	font-family: 'Inter';
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
	letter-spacing: 0.02em;
	text-align: left;
	color: #667085;
}

.user-collection-options{
	display: flex;
	gap: 12px;
	margin-top: 20px;
}

.user-collection-recipe-container{
	margin-top: 48px;
}

.user-collection-recipe-count{
	font-family: 'Inter';
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
	letter-spacing: 0.02em;
	text-align: left;

	color: #667085;
}

.user-collection-empty-collection{
	margin-top: 20px;
}
.user-collection-empty-collection p{
	font-family: 'Inter';
	font-size: 16px;
	font-weight: 400;
	line-height: 26px;
	letter-spacing: 0.02em;
	text-align: center;
	color: #98A2B3;
}


.user-collection-editing{
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: #10182899;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 24px;
	z-index: 5;
}

.user-collection-editing-card input{
	background: none;
}

.user-collection-deletion-card{
	background-color: white;
	width: 600px;
	padding: 24px;

	box-shadow: 0px 8px 22px -6px #18274B1F;
	border-radius: 16px;
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.user-collection-deletion-card h6{
	font-family: 'Inter';
	font-size: 18px;
	font-weight: 500;
	line-height: 28px;
	letter-spacing: 0.02em;
	text-align: left;
	color: #101828;
}
.user-collection-deletion-card p{
	font-family: 'Inter';
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
	letter-spacing: 0.02em;
	text-align: left;
	color: #667085;
}
.user-collection-deletion-option{
	display: flex;
	justify-content: end;
	gap: 12px;
}

.user-collection-editing-card{
	background-color: white;
	width: 600px;
	height: 348px;
	padding: 24px;

	box-shadow: 0px 8px 22px -6px #18274B1F;
	border-radius: 16px;
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.user-collection-editing-title{
	font-family: 'Inter';
	font-size: 24px;
	font-weight: 500;
	line-height: 40px;
	letter-spacing: 0.02em;
	text-align: center;
	color: #101828;
}


.user-collection-edit-name{
	display: flex;
	flex-direction: column;
	gap: 6px;
}
.user-collection-edit-name p{
	font-family: 'Inter';
	font-size: 14px;
	font-weight: 500;
	line-height: 22px;
	letter-spacing: 0.02em;
	text-align: left;
	color: #344054;
}

.user-collection-edit-name input{
	height: 26px;
	padding: 8px 7px 8px 15px;
	border-radius: 8px;
	border: 1px #D0D5DD solid;
	box-shadow: 0px 1px 2px 0px #18274B14;

	font-family: 'Inter';
	font-size: 16px;
	font-weight: 400;
	line-height: 26px;
	letter-spacing: 0.02em;
	text-align: left;
}

.user-collection-edit-brief{
	display: flex;
	flex-direction: column;
	gap: 6px;
}
.user-collection-edit-brief p{
	font-family: 'Inter';
	font-size: 14px;
	font-weight: 500;
	line-height: 22px;
	letter-spacing: 0.02em;
	text-align: left;
	color: #344054;
}

.user-collection-edit-brief textarea{
	height: 88px;
	padding: 7px 11px 7px 11px;
	border-radius: 8px;
	border: 1px #D0D5DD solid;
	box-shadow: 0px 1px 2px 0px #18274B14;

	font-family: 'Inter';
	font-size: 16px;
	font-weight: 400;
	line-height: 26px;
	letter-spacing: 0.02em;
	text-align: left;

	resize: none;
}

.user-collection-edit-buttons{
	gap: 12px;
	display: flex;
}

.user-collection-delete-container{
	order: 1;
	flex: 1;
}
.user-collection-edit-delete{
	width: fit-content;
}
.user-collection-edit-submit{
	order: 3;
}
.user-collection-edit-cancel{
	order: 2;
}

@media (max-width: 767px){
	.user-collection-recipes-list{
		grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
		gap: 12px;
	}
	.user-collection-option p{
		text-align: center;
	}
	.user-collection-option{
		width: 100%;
	}

	.user-collection-edit-buttons{
		flex-direction: column;
	}
	.user-collection-delete-container{
		order: 3;
	}
	.user-collection-edit-submit{
		order: 1;
	}
	.user-collection-editing-card{
		height: 460px;
		width: 100%;
	}
	.user-collection-edit-delete{
		width: 100%;
	}
}

.hidden{
	display: none;
}

