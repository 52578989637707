/* Spinner.css */
.spinner {
    border: 8px solid rgba(0, 0, 0, 0.1);
    border-left-color: #ff6f00;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* 使加载动画居中 */
.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
