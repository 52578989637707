.recipe-page-description{
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.recipe-page-description-header{
	display: flex;
}

.recipe-page-description-detail{
	font-family: 'Inter';
	font-size: 16px;
	font-weight: 400;
	line-height: 26px;
	letter-spacing: 0.02em;

	color: #475467;
}

