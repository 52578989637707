.recipe-page-header{
	min-height: 456px;
	display: flex;
	gap: 48px;
	width: 100%;
	align-items: center; 
}
.recipe-page-header-separator-quantity{
	height: 58px;
	outline: 1px #D0D5DD solid; 
}
.recipe-page-header-separator-date{
	height: 20px;
	outline: 1px #D0D5DD solid; 
}
.recipe-page-header-image{
	flex: 1;
	overflow: hidden;
	width: 100%;
	height: 100%;
	max-height: 456px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 12px;
}
.recipe-page-header-image img{
	height: 100%;
	border-radius: 12px;
}

.recipe-page-header-details{
	align-items: center;
	justify-content: center;
	flex: 1;
	display: flex;
	flex-direction: column;
	gap: 48px;
}

.recipe-page-header-recipe-description{
	display: flex;
	flex-direction: column;
	gap: 20px;
	justify-content: center;
}
.recipe-page-header-title{
	font-family: 'Inter';
	font-size: 36px;
	font-weight: 500;
	line-height: 44px;
	letter-spacing: -0.02em;
	text-align: center;
}
.recipe-page-header-author{
	display: flex;
	gap: 8px; 
}
.recipe-page-header-author-time{
	display: flex; 
	width: 100%;
	align-items: center;
	justify-content: center; 
	gap: 20px;


	font-family: 'Inter';
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
	letter-spacing: 0.02em;
	text-align: left;
	color: #667085;
}
.recipe-page-header-author a{
	text-decoration: none;
	font-weight: 500;
	color: #667085;
}

.recipe-page-header-quantities-container{
	display: flex;
	gap: 20px;
	align-items: center;
	justify-content: center;
	height: 60px;
}

.recipe-page-header-quantity{
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 4px;
	padding: 3px 0;
}
.recipe-page-header-quantity img{
	width: 24px;
	height: 24px;
}
.recipe-page-header-quantity p{
	font-family: 'Inter';
	font-size: 16px;
	font-weight: 400;
	line-height: 26px;
	letter-spacing: 0.02em;
	text-align: center;

	color: #475467;
}

.recipe-page-header-stats{
	width: 100%;
	max-width: 354px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 20px; 
	box-sizing: border-box;
}
.recipe-page-header-share{
	margin-left: auto;
	display: flex; 
}


.recipe-page-header-share-card-container{
	width: 0px;
	height: 0;
	position: relative;
	display: flex;
}
.recipe-page-header-share-card{
	width: 256px;
	height: 148px;
	display: flex;
	flex-direction: column;
	border-radius: 8px;
	padding: 8px 0;
	background-color: white;
	box-shadow: 0px 4px 14px -6px #18274B1F;
	z-index: 2;
	position: absolute;
	left: -80px;
	top: 36px;
	box-sizing: border-box;
}
.recipe-page-header-share-item{
	width: 100%;
	height: 44px;
	padding: 11px 44px 11px 16px;
	display: flex;
	gap: 12px;
	align-items: center;
	box-sizing: border-box;
}
.recipe-page-header-share-item p{
	font-family: Inter;
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
	letter-spacing: 0.02em;
	text-align: left;
	color: #344054;
}

.recipe-page-header-share-card-title{
	display: none;
}

@media (max-width: 1024px){
	.recipe-page-header{
		min-height: 292px;
		height: fit-content;
	}
	.recipe-page-header-title{
		font-family: 'Inter';
		font-size: 30px;
		line-height: 50px;
	}
	.recipe-page-header-image{
		height: 100%;
	}
}

@media (max-width: 767px){
	.recipe-page-header{
		flex-direction: column;
		min-height: 604px;
		gap: 20px;
		height: fit-content;
	}
	.recipe-page-header-details{
		order: 2;
		gap: 20px;
		height: 100%;
	}
	.recipe-page-header-title{
		font-family: 'Inter';
		font-size: 24px;
		line-height: 40px;
	}
	.recipe-page-header-image{
		order: 1;
		height: 342px;
		flex: 0 0 342px;
	}
	.recipe-page-header-stats{
		margin-top: 28px;
	}
	.recipe-page-header-recipe-description{
		gap: 8px;
	}
	.recipe-page-header-share-card-container{
		position: fixed;
		z-index: 2;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		background-color: #10182899;
	}
	.recipe-page-header-share-card{
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		margin-top: auto; 
		display: flex;
		flex-direction: row;
		gap: 0;
		height: 72px;
		padding: 0;
		border-radius: 0;
		box-shadow: none;
		border: none;
	}
	.recipe-page-header-share-card img{
		width: 30px;
		height: 30px;
	}
	.recipe-page-header-share-card-title{
		margin-top: auto; 
		position: absolute;
		bottom: 72px;
		width: 100%;
		background-color: white; 
		height: 44px;
		border-bottom: 1px #EAECF0 solid; 
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;

		font-family: 'Inter'; 
		font-size: 14px;
		font-weight: 500;
		line-height: 22px;
		letter-spacing: 0.02em;
		text-align: center;
		color: #344054;

		border-radius: 8px 8px 0 0;
	}
	.recipe-page-header-share-item p{
		padding: 0;
		font-family: Inter;
		font-size: 12px;
		font-weight: 400;
		line-height: 20px;
		letter-spacing: 0.02em;
		text-align: left;

	}
	.recipe-page-header-share-item{
		display: flex;
		gap: 0;
		flex-direction: column; 
		width: fit-content;
		flex: 1;
	}
}












