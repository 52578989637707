html, body {
    margin: 0;
    padding: 0;
    height: 100%;
  }
  
  .login-container {
    display: flex;
    width: 100%;
    min-height: 100vh; 
}

.login-image-section {
    flex: 0 0 45%; /* 45% 占比宽度 */
    background: url('../../../../public/background-1.png') no-repeat center center;
    background-size: cover;
}

.login-form-section {
    flex: 0 0 55%;
    display: flex;
    align-items: flex-start; /* 从顶部开始对齐内容 */
    justify-content: center;
    box-sizing: border-box;
}


.login-form-container {
    width: 100%;
    max-width: 472px;
    display: flex;
    flex-direction: column;
    align-items: center; /* 水平方向内容居中 */
    padding-top: 120px; /* 确保顶部距离为120px */
    padding-bottom: 120px;
    box-sizing: border-box;
}
  
@media (min-width: 768px) and (max-width: 1024px) {
    .login-image-section {
        display: none; /* 隐藏左边的图片部分 */
    }

    .login-form-section {
        flex: 0 0 100%; /* 右边的内容部分占据100%宽度 */
        padding: 0 148px; /* 缩小左右 padding */
    }

    .login-form-container {
        max-width: 472px; /* 调整 max-width */
        margin: 0 auto; /* 水平居中 */
    }
}


.logo {
    width: 138.13px;
    height: 93px;
}
  
.login-page-header{
    display: flex;
    align-items: center;
    margin-top: 48px; /* 距离logo为48px */
    text-align: left; /* 左对齐 */
    width: 100%; /* 宽度占满父容器 */
}
  
.title {
    width: 472px;
    height: 44px;
    font-size: 36px;
    line-height: 44px;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    margin: 0;
}

.subtitle {
    width: 472px;
    height: 28px;
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    margin-top: 8px; 
    color: rgba(247, 144, 9, 1);
}

  
.facebookheader {
    display: flex;
    align-items: center;
    margin-top: 48px; 
    text-align: left; /* 左对齐 */
    width: 100%; /* 宽度占满父容器 */
}

.googleheader{
    display: flex;
    align-items: center;
    margin-top: 50px; 
    text-align: left; /* 左对齐 */
    width: 100%; /* 宽度占满父容器 */
}

.lineheader{
    display: flex;
    align-items: center;
    margin-top: 8px; 
    text-align: left; /* 左对齐 */
    width: 100%; /* 宽度占满父容器 */
}
  

.button {
    width: 472px;
    height: 44px;
    padding: 0 16px;
    border: 1px solid #D0D5DD; /* 边框颜色 */
    border-radius: 100px; /* 圆角 */
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.02em;
    display: flex;
    align-items: center;
    justify-content: center; 
    cursor: pointer;
    background-color: white; /* 按钮背景色 */
    color: #333; /* 文本颜色 */
    text-align: center; 
    gap: 8px; /* 图标和文本之间的间距 */
  }
  .button:hover{
    background-color: #D0D5DD;
  }

  
  .facebook-icon {
    width: 20px; /* 图标宽度 */
    height: 20px; /* 图标高度 */
  }
  
  .google-icon {
    width: 20px; /* 图标宽度 */
    height: 20px; /* 图标高度 */
  }
  
  .line-icon {
    width: 20px; /* 图标宽度 */
    height: 20px; /* 图标高度 */
  }


.login-dividerheader {
    display: flex;
    align-items: center;
    margin-top: 48px; 
    text-align: center; 
    width: 100%; 
    
}

.login-divider {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%; 
}

.login-divider span {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #667085; /* 灰色文本颜色 */
    background-color: transparent; 
    text-align: center;
}

.login-divider-line {
    flex: 1;
    height: 1px;
    background-color: #EAECF0;
    opacity: 1;
    margin: 0 8px; /* 线条和or之间的间距 */
}
  
  
.input-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* 从左对齐 */
    justify-content: center;
    width: 100%;
    margin-top: 48px;
}


.input-email{
    width: 37px;
    height: 22px;
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    margin: 0; 
    color: #344054;
    align-items: center; /* 左对齐 */
    white-space: nowrap; /* 不換行 */
}

.email-input {
    width: 472px; /* 填满宽度 */
    height: 44px; /* 固定高度 */
    padding: 9px 8px 9px 16px !important;
    gap: 8px; 
    border-radius: 8px; /* 圆角设置 */
    border: 1px solid #D0D5DD; /* 边框颜色 */
    opacity: 1; /* 确保可见性 */
    box-sizing: border-box; 
    margin-top: 6px; /* 与上一个元素的间距 */
    align-items: center;
    justify-content: center; 
    text-align: left; /* 左对齐 */
}

.email-input::placeholder {
    font-family: 'Inter', sans-serif; /* 字体 */
    font-size: 16px; /* 字体大小 */
    font-weight: 400; /* 字体粗细 */
    line-height: 26px; /* 行高 */
    letter-spacing: 0.02em; /* 字间距 */
    color: #98A2B3; /* 字体颜色 */
    text-align: left; /* 左对齐 */
    opacity: 1; /* 确保文本可见 */
}
.input-password {
    width: 68px; /* 宽度 */
    height: 22px; /* 高度 */
    font-family: 'Inter', sans-serif; /* 字体 */
    font-size: 14px; /* 字体大小 */
    font-weight: 500; /* 字体加粗 */
    line-height: 22px; /* 行高 */
    letter-spacing: 0.02em; /* 字间距 */
    color: #344054; /* 文字颜色 */
    text-align: left; /* 左对齐 */
    margin-top: 20px; /* 与上一个元素的间距 */
    background-color: transparent;
    opacity: 1; /* 确保元素可见 */
}

.password-input {
    width: 472px; /* 填满宽度 */
    height: 44px; /* 固定高度 */
    padding: 9px 44px 9px 0 !important;
    gap: 8px; 
    border-radius: 8px; /* 圆角设置 */
    border: 1px solid #D0D5DD; /* 边框颜色 */
    opacity: 1; /* 确保可见性 */
    box-sizing: border-box; 
    margin-top: 6px; /* 与上一个元素的间距 */
}

/* 确保输入框内容有合适的内边距 */
.password-input .ant-input {
    padding-left: 9px !important; /* 设置内容的左侧内边距 */
    padding-right: 16px !important; /* 设置内容的右侧内边距 */
}

/* 如果没有使用前缀或后缀，确保它们不占用空间 */
.password-input .ant-input-prefix{
    display: none !important; /* 隐藏前缀和后缀 */
}

.password-input input::placeholder {
    font-family: 'Inter', sans-serif !important; /* 字体 */
    font-size: 16px !important; /* 字体大小 */
    font-weight: 400 !important; /* 字体粗细 */
    line-height: 26px !important; /* 行高 */
    letter-spacing: 0.02em !important; /* 字间距 */
    color: #98A2B3 !important; /* 字体颜色 */
    text-align: left !important; /* 左对齐 */
    opacity: 1 !important; /* 确保文本可见 */
}


  .login-button {
    width: 472px; /* 填满宽度 */
    height: 44px; /* 使用默认高度 44px */
    padding: 0 16px; /* 左右内边距 */
    gap: 8px; /* 元素间距 */
    border-radius: 100px; /* 设置圆角 */
    background-color: #F79009; /* 按钮背景颜色 */
    color: #FFFFFF; /* 按钮文字颜色 */
    border: none; /* 移除默认边框 */
    display: flex; /* 使用 flex 布局 */
    align-items: center; /* 垂直居中 */
    justify-content: center; /* 水平居中 */
    cursor: pointer; /* 鼠标悬停时显示为手形 */
    margin-top: 20px; /* 与上一个元素的间距 */
    opacity: 1; /* 确保按钮可见 */

    /* 按钮文字样式 */
    font-family: 'Inter', sans-serif; /* 字体 */
    font-size: 14px; /* 字体大小 */
    font-weight: 500; /* 字体粗细 */
    line-height: 22px; /* 行高 */
    letter-spacing: 0.02em; /* 字间距 */
    text-align: left; /* 文字左对齐 */
}

.login-button:hover{
    background-color:#DC6803;
}

.forgot-password-container {
    display: flex;
    align-items: center; /* 垂直居中 */
    justify-content: flex-start; /* 左对齐 */
    margin-top: 31px; /* 与上一个元素的间距 */
}

.forgot-password-link {
    font-family: 'Inter', sans-serif; /* 字体 */
    font-size: 14px; /* 字体大小 */
    font-weight: 500; /* 字体粗细 */
    line-height: 22px; /* 行高 */
    letter-spacing: 0.02em; /* 字间距 */
    text-align: left; /* 文字左对齐 */
    color: #F79009; /* 链接文本颜色 */
    text-decoration: none; /* 去除默认的下划线 */
    cursor: pointer; /* 鼠标悬停时显示手指指针 */
}

.forgot-password-link:hover {
    background: var(--Primary-100, #FEF0C7);
}

.no-account-container {
    display: flex;
    align-items: center; /* 垂直居中 */
    justify-content: flex-start; /* 左对齐 */
    margin-top: 48px; /* 与上一个元素的间距 */
}

.no-account-text {
    font-family: 'Inter', sans-serif; /* 字体 */
    font-size: 12px; /* 字体大小 */
    font-weight: 400; /* 字体粗细 */
    line-height: 20px; /* 行高 */
    letter-spacing: 0.02em; /* 字间距 */
    color: #344054; /* 文字颜色 */
    margin-right: 8px; /* 与链接的间距 */
}

.register-link {
    font-family: 'Inter', sans-serif; /* 字体 */
    font-size: 14px; /* 字体大小 */
    font-weight: 500; /* 字体粗细 */
    line-height: 22px; /* 行高 */
    letter-spacing: 0.02em; /* 字间距 */
    color: #667085; /* 链接文字颜色 */
    text-align: left; /* 左对齐 */
    text-decoration: none; /* 移除下划线 */
}

.agreement-container {
    width: 308px; /* 宽度 */
    height: 40px; /* 高度 */
    display: flex;
    flex-wrap: wrap; 
    justify-content: center; /* 使内容居中 */
    align-items: center; /* 垂直居中 */
    text-align: center; /* 文字居中 */
    margin-top: 8px;
}

.agreement-text {
    font-family: 'Inter', sans-serif; /* 字体 */
    font-size: 12px; /* 字体大小 */
    font-weight: 400; /* 字体粗细 */
    line-height: 20px; /* 行高 */
    letter-spacing: 0.02em; /* 字间距 */
    text-align: center; /* 文字居中 */
    color: #98A2B3;
}

.kanga-login .ant-input-password-icon {
    width: 36px;
    height: 36px;
    font-size: 20px; /* 确保图标大小适配 */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: -38px;
}



@media (max-width: 768px) {
    .login-image-section {
        display: none; 
    }

    .login-form-section {
        flex: 0 0 100%; 
        padding: 0 24px; 
    }

    .login-form-container {
        max-width: 342px; 
        margin: 0 auto; 
        padding-top: 72px; /* 确保顶部距离为120px */
        padding-bottom: 180px;
    }
    .logo {
        width: 124.77px !important; 
        height: 84px!important; 
    }

    .title {
        width: 342px;
        height: 50px !important; 
        font-size: 30px !important; 
        line-height: 50px !important; 
        font-weight: 500;
        font-family: 'Inter', sans-serif;
        margin: 0;
    }

    .subtitle {
        width: 342px;
        height: 52px;
        font-size: 16px;
        line-height: 26px;
        font-weight: 400;
        font-family: 'Inter', sans-serif;
        margin-top: 8px; 
        color: rgba(247, 144, 9, 1);
    }

    .button {
        width: 342px;
      }
    
      .input-header .input-email{
        width: 342px;
        margin: 0;
        text-align: left; 
        
      }
      .input-header .input-password{
        width: 342px;
        margin: 0;
        margin-top: 20px;
        text-align: left; 
      }
      .input-header .email-input,
      .input-header .password-input {
          width: 342px;
          text-align: left; /* 左对齐 */
      }
      
      .input-header .login-button{
        width: 342px;
      }
    
}
