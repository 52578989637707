.cart-dish-component{
	width: 340px;
	height: 103px;
	display: flex;
	padding: 12px;
	gap: 8px;
	box-sizing: border-box;
	border-radius: 15px;
	box-shadow: 2px 2px 4px 0px #0000000D;
	background-color: white;
	cursor: pointer;
}
.cart-dish-component:hover{
	background-color: #EAECF0;
}

.cart-dish-image-container{
	width: 85px;
	height: 79px;
	display: flex;
	overflow: hidden;
	align-items: center;
	justify-content: center;
	border-radius: 15px;
}

.cart-dish-image-container img{
	min-width: 100%;
	min-height: 100%;
	object-fit: scale-down;
}

.cart-dish-text-container{
	display: flex;
	box-sizing: border-box;
	padding: 16px;
	width: 195px;
	height: 78px;
	flex-direction: column;
	justify-content: center;
}

.cart-dish-title{
	font-family: 'Inter';
	font-size: 14px;
	font-weight: 500;
	line-height: 22px;
	letter-spacing: 0.02em;
	text-align: left;
	color: #344054;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.cart-dish-supporting-text{
	font-family: 'Inter';
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
	letter-spacing: 0.02em;
	text-align: left;
	color: #667085;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.cart-dish-delete-icon-container{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 20px;
	height: 20px;
}
.cart-dish-delete-icon-container img{
	width: 12px;
	height: 12px;
}

@media (max-width: 1024px){
	.cart-dish-component{
		width: 314px;
		height: 79px;
	}
	.cart-dish-image-container{
		width: 59px;
		height: 55px;
	}
	.cart-dish-text-container{
		height: 55px;
		padding: 0 12px;
	}
}


