.not-found-container{
	display: flex;
	box-sizing: border-box;
	padding-left: 320px;
}

@media(max-width: 1024px){
	.not-found-container{
		padding-left: 0;
	}
}

