.user-profile-user-description{
	display: grid;
	grid-row-gap: 20px;
	min-height: 190px;
	margin: 0;
	padding: 0;
}

.user-profile-detailed-description{
	display: flex;
	width: 100%;
	gap: 48px;
	height: 56px;
	align-items: center;
}

.user-profile-avatar-container{
	flex: 1;
	display: flex;
}

.user-profile-stats{
	align-items: middle;
	height: 52px;
}
.user-profile-stats-count{
	text-align: center;
	font-family: 'Inter';
	font-weight: 500;
	font-size: 16px;
	line-height: 26px;
	color: #344054;
}

.user-profile-stats-name{
	text-align: center;
	font-family: 'Inter';
	font-weight: 400;
	font-size: 16px;
	line-height: 26px;
	color: #344054;
}

.user-profile-user-name{
	height: 50px;
	font-family: 'Inter';
	font-weight: 500;
	font-size: 30px;
	line-height: 50px;
	color: #344054;
}

.user-profile-user-bio{
	width: 100%;
}
.user-profile-user-bio p{
	font-family: 'Inter';
	font-weight: 400;
	font-size: 20px;
	color: #667085;
	width: 100%;
	overflow-wrap: anywhere;
}

.user-profile-user-options{
	display: flex;
	gap: 12px;
}

.user-profile-create-recipe{
	cursor: pointer;
	font-family: 'Inter';
	font-weight: 500;
	font-size: 14px;
	line-height: 22px;
	padding: 0px 16px;
	height: 44px;
	line-height: 44px;
	text-align: middle;
	color: #667085;
}

.user-profile-edit-profile{
	cursor: pointer;
	font-family: 'Inter';
	font-weight: 500;
	font-size: 14px;
	line-height: 22px;
	padding: 0px 16px;
	height: 44px;
	line-height: 44px;
	text-align: middle;
	color: #667085;
}

.user-profile-user-options-selected{
	outline: 1px #D0D5DD solid;
	border-radius: 999px;
	box-sizing: border-box;
	color: #344054;
	box-shadow: 0px 1px 2px rgba(24, 39, 75, 0.08);
}

@media (max-width: 767px){
	.user-profile-detailed-description{
		gap: 20px;
	}
	.user-profile-stats-count, .user-profile-stats-name{
		font-size: 14px;
		line-height: 22px;
	}
	.user-profile-user-name{
		height: 40px;
		font-size: 24px;
		line-height: 40px;
	}
}
.pointer{
	cursor: pointer;
}

