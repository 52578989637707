.btn {
    height: 44px;
    border-radius: 100px;
    box-sizing: border-box;
    padding: 0px 16px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    border: none;
    cursor: pointer;
}

.btn p {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
}

.primary.default {
    background: #F79009;
}

.primary:hover {
    background: #DC6803;
}

.primary.focused {
    background: #DC6803;
}

.primary.disabled {
    background: #FEDF89;
}


.primary p {
    color: #FFFFFF;
}


.secondary {
    box-sizing: border-box;
    border: 1px solid #D0D5DD;
}

.secondary.default, .secondary.disabled {
    background: #FFFFFF;
}

.secondary.focused {
    background: #F9FAFB;
}

.secondary:hover {
    background: #F9FAFB;
    color: #344054;
}

.secondary:is(.default, .selected) p {
    color: #344054;
}

.secondary.disabled p {
    color: #D0D5DD
}

.primary:hover {
    background: #DC6803;
}

.primary.focused {
    background: #DC6803;
}

.primary.disabled {
    background: #FEDF89;
}