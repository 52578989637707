.popup-shadow {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(16, 24, 40, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
    overflow-y: auto;
    align-items: flex-start;
    padding: 24px;
  }
  
.popup-content {
  position: relative;
  background-color: rgba(255, 255, 255, 1);
  width: 600px;
  margin: auto;
  border-radius: 16px;
  overflow-y: auto;
  box-shadow: 0px 14px 64px -4px rgba(24, 39, 75, 0.12);
}

.popup-content h2 {
  margin-top: 0;
  font-size: 24px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0.02em;
  text-align: center;
}

.popup-content .button-box button {
  margin-top: 10px;
}

.popup-content p, .popup-content h2 {
  text-align: center;
  font-family: 'Inter', sans-serif;
  color: #344054;
}

.popup-content p {
  font-size: 14px;
  color: #344054;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 2%;
  text-align: left;
}
