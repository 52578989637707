/* .google-login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  } */
  
  .google-login-button {
  width: 472px;
  height: 44px;
  padding: 0 16px;
  border: 1px solid #D0D5DD; /* 边框颜色 */
  border-radius: 100px; /* 圆角 */
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.02em;
  display: flex;
  align-items: center;
  justify-content: center; 
  cursor: pointer;
  background-color: white; /* 按钮背景色 */
  color: #333; /* 文本颜色 */
  text-align: center; 
  gap: 8px; /* 图标和文本之间的间距 */
  box-shadow: none !important; /*Remove button shadow*/
}

.google-login-button:hover{
  background-color: #F9FAFB!important;
  color: black !important;
}

@media screen and (max-width: 768px) { /*RWD for mobile users*/
  .google-login-button{
    max-width: 342px;
  }
}
