.recipe-page-header-share-toast-container {
	width: 100%;
	left: 0;
	position: fixed;
	top: 103px;
	box-sizing: border-box;
	padding-left: 320px;
}

.recipe-page-header-share-toast {
	width: 371px;
	margin: auto;
	border: 1px solid #669f2a;
	box-sizing: border-box;
	background-color: #f5fbee;
	height: 44px;
	display: flex;
	align-items: center;
	padding: 11px 16px;
	gap: 8px;
	border-radius: 8px;
}

.recipe-page-header-share-toast p{
	font-family: "Inter";
	font-size: 14px;
	font-weight: 500;
	line-height: 22px;
	letter-spacing: 0.02em;
	text-align: left;
	color: #3F621A;
}
@media (max-width: 1024px){
	.recipe-page-header-share-toast-container {
		padding-left: 0;
	}
}

@media (max-width: 767px){
	.recipe-page-header-share-toast-container {
		top: 63px;
	}
	.recipe-page-header-share-toast{
		width: 270px;
	}
}

