.user-profile-saved-recipes{
	display: flex;
	align-items: center;
	justify-content: center;
}
.user-profile-saved-recipes-list-container{
	width: 100%;
}

.user-profile-saved-recipe-create-collection{
	margin-top: 20px;
	display: flex;
	width: 100%;
	align-items: end;
	justify-content: end;
}
.user-profile-saved-recipe-create-collection img{
	cursor: pointer;

	width: 20px;
	height: 20px;
	padding: 11px;
}

.user-profile-saved-recipes-empty{
	margin-top: 20px;
}

.user-profile-saved-recipes-empty p{
	font-weight: 400;
	font-family: 'Inter';
	font-size: 16px;
	line-height: 26px;
	color: #6C757D;
	display: flex;
	align-items: center;
	justify-content: center;
}

.user-profile-saved-recipes-create-recipe{
	margin-top: 20px;
	display: flex;
	cursor: pointer;
}

.user-profile-saved-recipes-create-recipe img{
	width: 307px;
	height: 342px;
}

.user-profile-saved-recipes-list{
	margin-top: 20px;
	width: 100%;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 20px; /* Adjust gap between grid items */
}
.user-profile-saved-recipe-card{
	display: inline-block;
}

.user-profile-collection-creation-container{
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: #10182899;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 24px;
	z-index: 5;
}
.user-profile-collection-creation-title{
	font-family: 'Inter';
	font-size: 24px;
	font-weight: 500;
	line-height: 40px;
	letter-spacing: 0.02em;
	text-align: center;
	color: #101828;

}
.user-profile-collection-creation-card{
	background-color: white;
	width: 600px;
	padding: 24px;

	box-shadow: 0px 8px 22px -6px #18274B1F;
	border-radius: 16px;
	display: flex;
	flex-direction: column;
	gap: 20px;
}
.user-profile-collection-creation-option p{
	font-family: 'Inter';
	font-size: 14px;
	font-weight: 500;
	line-height: 22px;
	letter-spacing: 0.02em;
	text-align: left;
	color: #344054;
}
.user-profile-collection-creation-option textarea{
	height: 88px;
	padding: 7px 11px 7px 11px;
	border-radius: 8px;
	border: 1px #D0D5DD solid;
	box-shadow: 0px 1px 2px 0px #18274B14;

	font-family: 'Inter';
	font-size: 16px;
	font-weight: 400;
	line-height: 26px;
	letter-spacing: 0.02em;
	text-align: left;

	resize: none;
}
.user-profile-collection-creation-option input{
	height: 26px;
	padding: 8px 7px 8px 15px;
	border-radius: 8px;
	border: 1px #D0D5DD solid;
	box-shadow: 0px 1px 2px 0px #18274B14;

	font-family: 'Inter';
	font-size: 16px;
	font-weight: 400;
	line-height: 26px;
	letter-spacing: 0.02em;
	text-align: left;
}
.user-profile-collection-creation-option{
	display: flex;
	flex-direction: column;
	gap: 6px;
}

.user-profile-collection-creation-buttons{
	gap: 12px;
	display: flex;
	justify-content: end;
}



@media (max-width: 767px){
	.user-profile-saved-recipes-list{
		grid-template-columns: repeat(auto-fill, minmax(165px, 1fr));
		gap: 12px;
	}
	.user-profile-collection-creation-buttons{
		flex-direction: column;
	}
	.user-profile-collection-creation-cancel{
		order: 2;
	}
	.user-profile-collection-creation-create{
		order: 1;
	}
}

