/* EmailSignupPage.css */

.ES-container{
    width: 100%;
    height: 1173px;
    display: flex;
}

.ES-img-section {
    flex: 0 0 45%;
    background: url('../../../../public/RecipeCard_SignupPage.jpeg') no-repeat center center;
    background-size: cover;
}

.ES-form-section {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; 
    box-sizing: border-box;
    position: relative;
}


.ES-form-container{
    width: 100%;
    max-width: 472px;
    display: flex;
    flex-direction: column;
    align-items: center; /* 水平方向内容居中 */
    padding-top: 0; /* 确保顶部距离为120px */
    padding-bottom: 120px;
    box-sizing: border-box;
}


@media (min-width: 768px) and (max-width: 1024px) {
    .ES-img-section {
        display: none; /* 隐藏左边的图片部分 */
    }
  
    .ES-form-section {
        flex: 0 0 100%; /* 右边的内容部分占据100%宽度 */
        padding: 0 148px; /* 缩小左右 padding */
    }
  
    .ES-form-container {
        max-width: 472px; /* 调整 max-width */
        margin: 0 auto; /* 水平居中 */
    }
  }
 
.ES-form-frame {
    width: 100%; 
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
    margin-top: -250px;
}

.ES-logo{
    width: 138.13px;
    height: 93px;
    margin-top: 0;
}


.arrow-left-button{
    background-color: white;
    width: 44px;
    height: 44px;
    border: 1px solid #D0D5DD;
    border-radius: 50%;
    display: flex; 
    justify-content: center; 
    align-items: center;
    cursor: pointer;
    display: inline-block;
    margin-right: 10px;
    transition: background-color 0.3s ease;
}

.arrow-left-button:hover{
    background-color: #F9FAFB;
}

.arrow-left{
    width: 20px;
    height: 20px;
    vertical-align: middle;
    margin-bottom: 10px;
}


.signup-form{
    margin-top: 50px;
    display: flex;
    position: relative;
    /* background-color: gray; */
    text-align: left;
    height: auto;
    width: 472px;
    flex-direction: column;
}

.username-text{
    margin-top: 0px;
    width: 100%;
    height: fit-content;
    display: block;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    color: #344054;
}

.lable-text{
    margin-top: 0px;
    width: 100%;
    height: fit-content;
    display: block;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    color: #344054;
}

.placeholder-text{
    font-family: 'Inter', sans-serif; /* 字体 */
    font-size: 16px; /* 字体大小 */
    font-weight: 400; /* 字体粗细 */
    line-height: 26px; /* 行高 */
    letter-spacing: 0.02em; /* 字间距 */
    color: #98A2B3; /* 字体颜色 */
    text-align: left; /* 左对齐 */
    opacity: 1; /* 确保文本可见 */
}

.input-box{
    display: flex;
    width: 100%; /* 填满宽度 */
    height: 44px; /* 固定高度 */
    padding: 9px 8px 9px 16px !important;
    gap: 8px; 
    border-radius: 8px; /* 圆角设置 */
    border: 1px solid #D0D5DD; /* 边框颜色 */
    opacity: 1; /* 确保可见性 */
    box-sizing: border-box; 
    margin-top: 6px; 
    padding: 0 8px;
    padding-right: 36px;
}

.input-box::placeholder{
    font-family: 'Inter', sans-serif !important; /* 字体 */
    font-size: 16px; /* 字体大小 */
    font-weight: 400; /* 字体粗细 */
    line-height: 26px; /* 行高 */
    letter-spacing: 0.02em; /* 字间距 */
    color: #98A2B3 !important; /* 字体颜色 */
    text-align: left; /* 左对齐 */
    opacity: 1; /* 确保文本可见 */
}

.signup-input-email{
    width: 37px;
    height: 22px;
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    margin: 0; 
    color: #344054;
    align-items: center; /* 左对齐 */
    margin-top: 20px; /* 与上一个元素的间距 */
    white-space: nowrap;
}

.signup-email-input {
    width: 472px; /* 填满宽度 */
    height: 44px; /* 固定高度 */
    padding: 9px 8px 9px 16px !important;
    gap: 8px; 
    border-radius: 8px; /* 圆角设置 */
    border: 1px solid #D0D5DD; /* 边框颜色 */
    opacity: 1; /* 确保可见性 */
    box-sizing: border-box; 
    margin-top: 6px; /* 与上一个元素的间距 */
    align-items: center;
    justify-content: center; 
    text-align: left; /* 左对齐 */
}

.signup-email-input::placeholder {
    font-family: 'Inter', sans-serif; /* 字体 */
    font-size: 16px; /* 字体大小 */
    font-weight: 400; /* 字体粗细 */
    line-height: 26px; /* 行高 */
    letter-spacing: 0.02em; /* 字间距 */
    color: #98A2B3; /* 字体颜色 */
    text-align: left; /* 左对齐 */
    opacity: 1; /* 确保文本可见 */
}


.signup-input-password {
    width: 68px; /* 宽度 */
    height: 22px; /* 高度 */
    font-family: 'Inter', sans-serif; /* 字体 */
    font-size: 14px; /* 字体大小 */
    font-weight: 500; /* 字体加粗 */
    line-height: 22px; /* 行高 */
    letter-spacing: 0.02em; /* 字间距 */
    color: #344054; /* 文字颜色 */
    text-align: left; /* 左对齐 */
    margin-top: 20px; /* 与上一个元素的间距 */
    background-color: transparent;
    opacity: 1; /* 确保元素可见 */
}

.signup-password-input {
    width: 472px; /* 填满宽度 */
    height: 44px; /* 固定高度 */
    padding: 9px 44px 9px 0 !important;
    gap: 8px; 
    border-radius: 8px; /* 圆角设置 */
    border: 1px solid #D0D5DD; /* 边框颜色 */
    opacity: 1; /* 确保可见性 */
    box-sizing: border-box; 
    margin-top: 6px; /* 与上一个元素的间距 */
}

/* 确保输入框内容有合适的内边距 */
.signup-password-input .ant-input {
    padding-left: 9px !important; /* 设置内容的左侧内边距 */
    padding-right: 16px !important; /* 设置内容的右侧内边距 */
}

/* 如果没有使用前缀或后缀，确保它们不占用空间 */
.signup-password-input .ant-input-prefix{
    display: none !important; /* 隐藏前缀和后缀 */
}

.signup-password-input input::placeholder {
    font-family: 'Inter', sans-serif !important; /* 字体 */
    font-size: 16px !important; /* 字体大小 */
    font-weight: 400 !important; /* 字体粗细 */
    line-height: 26px !important; /* 行高 */
    letter-spacing: 0.02em !important; /* 字间距 */
    color: #98A2B3 !important; /* 字体颜色 */
    text-align: left !important; /* 左对齐 */
    opacity: 1 !important; /* 确保文本可见 */
}



.input-box .ant-input-suffix{
    right: 10px; 
    top: 50%; 
    transform: translateY(-50%);
    position: absolute;
}

.input-box .anticon {
    font-size: 30px; /* 调整图标大小 */
    color: #101828; /* 更改图标颜色 */
    display: inline;
}

.password-criteria-list{
    height: 80px;
    width: 100%;
    margin-top: 8px;
    margin-left: 20px;
}

.check-frame li{
    list-style: none;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-weight: 400;
    color: #98A2B3;
}

.agreement{
    margin-top: 20px;
}

.agreement-text{
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    color: #344054;
}

.verification{
    margin-top: 20px;
}

.signup-button{
    width: 472px; /* 填满宽度 */
    height: 44px !important; /* 使用默认高度 44px，并强制应用 */
    padding: 0 16px; /* 左右内边距 */
    gap: 8px; /* 元素间距 */
    border-radius: 100px; /* 设置圆角 */
    background-color: #FEDF89; /* 按钮背景颜色 */
    color: #FFFFFF; /* 按钮文字颜色 */
    border: none; /* 移除默认边框 */
    display: flex; /* 使用 flex 布局 */
    align-items: center; /* 垂直居中 */
    justify-content: center; /* 水平居中 */
    cursor: pointer; /* 鼠标悬停时显示为手形 */
    margin-top: 20px; /* 与上一个元素的间距 */
    opacity: 1; /* 确保按钮可见 */
    transition: background-color 0.3s ease;
    line-height: 44px; /* 确保文本行高一致 */
    box-sizing: border-box; /* 确保内边距不影响整体高度 */

    /* 按钮文字样式 */
    font-family: 'Inter', sans-serif; /* 字体 */
    font-size: 14px; /* 字体大小 */
    font-weight: 500; /* 字体粗细 */
    line-height: 22px; /* 行高 */
    letter-spacing: 0.02em; /* 字间距 */
    text-align: left; /* 文字左对齐 */
}

.signup-button:not(:disabled):hover {
    background-color: #F79009 !important; /* IDK how to fix hover issue so I use '!important'  -- Jake :(  */
}

.signup-button:disabled {
    width: 472px; /* 填满宽度 */
    height: 44px; /* 使用默认高度 44px */
    padding: 0 16px; /* 左右内边距 */
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;  /* Not allowed to click submit button */
}

.signup-error-message {
    display: flex;
    width: 100%;
    height: 22px;
    text-align: left;
    margin-top: 10px;
}

.alert-container img{
    width: 16px;
    height: 16px;
    /* display: inline-block; */
}

.signup-error-message span{
    color: #F04438;
    font-family: 'Noto Sans TC';
    font-size: 16px;
    font-weight: 400; 
    margin-left: 4px;
    line-height: 17px;
}

.alert-pic{
    width: 16px;
    height: 16px;
}

/* 390px - 768px 范围内的样式 */
@media (max-width: 768px) {
    .ES-img-section {
        display: none; /* 隐藏左边的图片部分 */
    }

    .ES-form-section {
        flex: 0 0 100%; /* 内容部分占据100%宽度 */
        padding: 0 24px; /* 缩小左右 padding */
    }

    .ES-form-container {
        max-width: 342px; /* 调整 max-width */
        margin: 0 auto; /* 水平居中 */
        padding-top: 72px; /* 确保顶部距离为72px */
        padding-bottom: 180px;
    }

    .signup-form {
        margin-top: 48px;
        max-width: 342px;
    }

    .input-box {
        width: 342px;
        height: 44px; /* 固定高度 */
        padding: 9px 8px 9px 16px !important;
        gap: 8px; 
        border-radius: 8px; /* 圆角设置 */
        border: 1px solid #D0D5DD; /* 边框颜色 */
        margin-top: 6px; /* 与上一个元素的间距 */
        box-sizing: border-box; 
    }
    .signup-password-input {
        width: 342px !important; /* 强制宽度为 342px */
    }

    .signup-email-input {
        width: 342px !important; /* 确保 Email 输入框也为 342px */
    }
    

    .signup-button {
        width: 342px !important;
        height: 44px;
        margin-top: 20px;
    }

    .has-account-container,
    .signup-agreement-text {
        width: 342px;
    }
}
