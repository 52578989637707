.user-profile-liked-recipes{
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: warp;
}

.user-profile-liked-recipes-empty{
	margin-top: 20px;
}

.user-profile-liked-recipes-empty p{
	font-weight: 400;
	font-family: 'Inter';
	font-size: 16px;
	line-height: 26px;
	color: #6C757D;
	display: flex;
	align-items: center;
	justify-content: center;
}

.user-profile-liked-recipes-list{
	margin-top: 20px;
	width: 100%;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 20px; /* Adjust gap between grid items */
}
.user-profile-liked-recipe-card{
	display: inline-block;
}
@media (max-width: 767px){
	.user-profile-liked-recipes-list{
		grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
		gap: 12px;
	}
}


