.recipe-container-header{
	width: 100%;
	height: 80px;
	border-bottom: 1px solid black;
	line-height: 81px;
	text-align: center;
	vertical-align: middle;
}

.recipe-container-nav-bar{
	width: 319px;
	height: calc(100vh - 81px);
	border-right: 1px solid black;

	line-height: calc(100vh - 81px);
	text-align: center;
	vertical-align: middle;
	flex-shrink: 0;
	box-sizing: border-box;
}

.recipe-container{
	width: 100%;
}
.recipe-container-body{
	display: flex;
	box-sizing: border-box;
	width: 100%;
}

.recipe-container-main-content{
	margin-top: 77px;
	width: 100%;
	flex-shrink: 1;
	flex-grow: 1;
	flex-basis: 0;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	gap: 48px;
	min-width: 0;
	padding: 48px 80px;
	background-color: #F9FAFB;
	padding-bottom: 150px;
}

.recipe-page-section-title{
	font-family: 'Inter';
	font-size: 20px;
	font-weight: 500;
	line-height: 34px;
	letter-spacing: 0.02em;

	color: black;
}

.recipe-page-collapse{
	margin-left: auto;
	display: none;
}


@media (max-width: 1024px){
	.recipe-container-header{
		height: 48px;
		line-height: 48px;
	}
	.recipe-container-nav-bar{
		display: none;
	}
	.recipe-container-main-content{
		margin-top: 48px;
		padding: 48px;
		padding-bottom: 200px;
	}
}

@media (max-width: 767px){
	.recipe-container-main-content{
		padding: 24px;
		padding-bottom: 200px;
	}
	.recipe-page-collapse{
		display: block;
	}

}
