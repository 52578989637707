.recipe-card{
	cursor: pointer;
	padding: 12px;
	border-radius: 24px;
	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
	background-color: white;
	height: 318px;
	transition: all 0.2s;
}

.recipe-card:hover{
	background-color: #EAECF0;
}


.recipe-card-image-container{
	width: 100%;
	height: 246px;
	overflow: hidden;
	align-items: center;
	display: flex;
	border-radius: 20px;
}

.recipe-card-image{
	border-radius: 20px;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.recipe-card-details{
	padding: 0 8px;
	white-space: nowrap;
	min-width: 0;
	margin-top: 8px;
}

.recipe-card-recipe-name{
	text-overflow: ellipsis;
	overflow: hidden;

	font-family: 'Inter';
	font-size: 16px;
	line-height: 26px;
	font-weight: 400;
	color: #344054;
}

.recipe-card-recipe-details{
	white-space: nowrap;
	min-width: 0;
	margin-top: 8px;
	display: flex;
	gap: 10px;
	align-items: center;
}

.recipe-card-author-name{
	text-overflow: ellipsis;
	overflow: hidden;

	font-family: 'Inter';
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	color: #667085;
	flex: 1;
	cursor: pointer;
}

@media (max-width: 767px){
	.recipe-card-author-name{
		display: none;
	}
	.recipe-card{
		height: 201px;
	}
	.recipe-card-image-container{
		height: 133px;
	}
	.recipe-card-recipe-name{
		height: 22px;
		font-size: 14px;
		line-height: 22px;
	}
}


