.otp-container {
    display: flex;
    align-items: center; 
    width: 100%;
    justify-content: flex-start;
    flex-wrap: wrap; /* 允许 Resend 按钮在小屏幕时换行 */
}

.otp-input-container {
    display: flex;
    gap: 12px; /* 输入框之间的间距 */
    align-items: center; /* 使输入框和标签垂直对齐 */
}

  
.otp-input {
width: 36px;
height: 44px;
padding: 9px;
border-radius: 8px;
border: 1px solid #D9D9D9; /* 默认的边框样式 */
text-align: center;
font-size: 18px;
font-family: 'Inter', sans-serif;
font-weight: 500;
line-height: 28px;
letter-spacing: 0.02em;
color: #98A2B3; /* 默认文本颜色 */
background-color: #FFFFFF; /* 默认背景颜色 */
}

/* 样式：输入框内容为空时 */
.otp-input::placeholder {
color: #98A2B3; /* 未输入时的颜色 */
}

/* 样式：输入框中有内容时 */
.otp-input.filled {
background-color: #FFFFFF; /* 输入后背景色 */
color: #344054; /* 输入后的文本颜色 */
}


.resend-button {
width: 176px;
height: 44px;
margin-left: 20px; 
border-radius: 100px;
border: 1px solid #D9D9D9;
background-color: #FFFFFF;
color: #000000;
display: flex;
align-items: center;
justify-content: center;
font-size: 16px;
cursor: pointer;
}

/* 禁用状态的样式 */
.resend-button[disabled] {
font-family: 'Inter', sans-serif;
font-size: 14px;
font-weight: 500;
line-height: 22px;
letter-spacing: 0.02em;
text-align: left;
color: #D0D5DD; /* 禁用时的文字颜色 */
background-color: #ffffff; /* 禁用时的背景颜色，可根据需要调整 */
cursor: not-allowed; /* 禁用时的鼠标样式 */
border: 1px solid #EAECF0; /* 禁用时的边框颜色 */
display: flex;  /* 保持使用flex */
align-items: center;
justify-content: center;
}

.custom-check-icon {
    margin-left: 20px; /* 与最后一个输入框间隔 20px */
}

  /* Form.Item 的样式，用于设置外边距 */
.reset-password-form-item {
    margin-top: 20px; /* 距离上方数字输入框的距离 */
    margin: 20px 0 0 0; /* 设置上边距为20px，下边距为0 */

}

/* 输入框样式 */
.custom-password-input {
width: 472px; /* 固定宽度 */
height: 44px; /* 固定高度 */
padding: 9px 16px; /* 上下内边距为9px，左右内边距按照变量或者固定值 */
border-radius: 8px; /* 边框圆角 */
border: 1px solid #D0D5DD; /* 边框颜色 */
color: #344054; /* 文本颜色，假设这个颜色是通用的 */
background-color: #FFFFFF; /* 背景颜色 */
font-family: 'Inter', sans-serif; /* 字体 */
font-size: 16px; /* 字体大小 */
line-height: 22px; /* 行高 */
letter-spacing: 0.02em; /* 字间距 */
box-sizing: border-box; /* 确保内边距不会增加总宽度 */
}

/* 设置占位符文本的样式 */
.custom-password-input::placeholder {
color: #98A2B3; /* 占位符文本颜色 */
opacity: 1; /* 确保占位符文本颜色可见 */
padding-left: 16px; /* 确保占位符距离左边界16px */
}


.custom-submit-button {
width: 472px;
height: 44px;
padding: 0 16px;
border-radius: 100px;
border: 1px solid #FEC84B;
background-color: #ffffff; /* 按钮背景颜色可以根据需要调整 */
color: #DC6803;
text-align: center;
font-size: 16px;
font-weight: 500;
line-height: 22px;
letter-spacing: 0.02em;
cursor: pointer;
margin-top: 20px;
}

.custom-submit-button:hover {
background-color: #FEF0C7 !important; /* 使用 !important 确保这个样式生效 */
}

.ant-input-password-icon {
    width: 36px;
    height: 36px;
    font-size: 20px; /* 确保图标大小适配 */
    display: flex;
    align-items: center;
    justify-content: center;
}
  

@media (max-width: 768px) {
    .otp-container {
        align-items: flex-start;
        flex-wrap: wrap; /* 确保 Resend 按钮能换行 */
    }

    .otp-input-container {
        display: flex;
        gap: 12px; /* 确保验证码输入框之间的间距 */
        align-items: center; /* 垂直居中对齐 */
    }

    .resend-button {
        margin-top: 20px; /* 确保按钮与输入框容器之间的距离为 20px */
        margin-left: 0; /* 确保左对齐 */
        display: block; /* 在小屏幕下将按钮独立换行 */
    }

    .custom-check-icon {
        margin-left: 20px; /* 依然保持与输入框间隔 20px */
        margin-top: 10px;
    }

    .custom-password-input {
        width: 342px !important;
        height: 44px;
        padding: 9px 16px;
        font-size: 14px;
    }

    .custom-submit-button {
        width: 342px !important;
        height: 44px;
        padding: 0 16px;
        border-radius: 100px;
        font-size: 14px;
        margin-top: 20px;
    }
}
