.user-profile-my-recipes{
}

.user-profile-my-recipes-order-by-container{
	margin-top: 20px;
	display: flex;
	height: 28px;
	gap: 12px;
	align-items: center;
	justify-content: end;
}

.user-profile-my-recipes-order-by{
	padding: 3px 8px;
	font-family: 'Inter';
	font-weight: 500;
	font-size: 14px;
	line-height: 22px;
	color: #667085;
}
.user-profile-my-recipes-order-by-selected{
	color: #344054;
	border-radius: 100px;
	background-color: #F2F4F7;
	box-shadow: 0px 1px 2px 0px #18274B14;
}

.user-profile-my-recipes-empty{
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 20px;
	flex-wrap: wrap;
}

.user-profile-my-recipes-empty p{
	flex-basis: 100%;

	font-family: 'Inter';
	font-size: 16px;
	line-height: 26px;
	color: #6C757D;
	display: flex;
	align-items: center;
	justify-content: center;
}

.user-profile-my-recipes-create-recipe{
	margin-top: 20px;
	display: flex;
}

.user-profile-my-recipes-create-recipe img{
	cursor: pointer;
	width: 307px;
	height: 342px;
}

.user-profile-my-recipes-list{
	margin-top: 20px;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 20px; /* Adjust gap between grid items */
}
.user-profile-my-recipe-card{
	display: inline-block;
}

@media (max-width: 767px){
	.user-profile-my-recipes-list{
		grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
		gap: 12px;
	}
}




