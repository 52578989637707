.recipe-page-author-info-container{
	display: flex;
	gap: 20px;
	flex-direction: column;
	align-items: center;
	background-color: #F2F4F7;
	padding: 20px;
	border-radius: 14px;
}

.recipe-page-author-details{
	display: flex;
	gap: 20px;
	align-items: center;
	background-color: #F2F4F7;
	width: 100%;
}

.recipe-page-author-name{
	flex: 1;
	display: flex;
	gap: 6px;
}

.recipe-page-author-name a{
	font-family: 'Inter';
	font-size: 18px;
	font-weight: 500;
	line-height: 28px;
	letter-spacing: 0.02em;
	text-align: left;
	color: #101828;
	text-decoration: none;
}
.recipe-page-author-name h6{
	font-family: 'Inter';
	font-size: 18px;
	font-weight: 500;
	line-height: 28px;
	letter-spacing: 0.02em;
	text-align: left;
	color: #101828;
}

.recipe-page-author-info{
	display: flex;
	gap: 6px;
	flex-direction: column;
	flex: 1;
}

.recipe-page-author-stats-container{
	display: flex;
	gap: 20px;
}
.recipe-page-author-stat{
	display: flex;
	gap: 5px;
}

.recipe-page-author-stat p{
	font-family: 'Inter';
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
	letter-spacing: 0.02em;
	text-align: left;
	color: #475467;
}

.recipe-page-follow-button-container{
	margin-left: auto;
}

.recipe-page-author-bio{
	font-family: 'Inter';
	font-size: 16px;
	font-weight: 400;
	line-height: 26px;
	letter-spacing: 0.02em;

	color: #475467;
	width: 100%;
}

.recipe-page-author-info-container-mobile{
	display: none;
	gap: 12px;
	flex-direction: column;
	align-items: center;
	background-color: #F2F4F7;
	padding: 20px;
	border-radius: 14px;
	justify-content: center;
}

.recipe-page-author-info-container-mobile *{
	align-items: center;
	justify-content: center;
	text-align: center;
}

.recipe-page-author-container{
	display: flex;
	gap: 20px;
	align-items: center;
	width: 100%;
}

@media (max-width: 767px){
	.recipe-page-author-details{
		flex-direction: column;
	}
	.recipe-page-author-stat{
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	.recipe-page-author-stat p{
		text-align: center;
	}
	.recipe-page-author-info{
		display: flex;
		gap: 48px;
		width: 100%;
	}
	.recipe-page-author-stats-container{
		gap: 48px;
	}
	.recipe-page-follow-button-container{
		width: 100%;
	}
	.recipe-page-author-info-container-mobile{
		display: flex;
	}
	.recipe-page-author-info-container{
		display: none;
	}
	.recipe-page-author-name{
		flex: none;
	}
}

.hidden{
	display: none;
}


