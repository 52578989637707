body {
    margin: 0px;
}

.cookie-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    background-color: rgba(16, 24, 40, 0.6);
    left: 0;
    z-index: 100;
}

.cookie-container {
    margin: 48px 80px;
    display: inline-flex;
    gap: 25px;
}


.text-box {
    max-width: 1064px;
    overflow: hidden; /* Hide overflow */
    display: flex; 
    justify-content: space-between;
}


.cookie-container .text-box p {
    color: #FFFFFF;
}

.text-box p {
    display: inline;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.02em;
    text-align: left;
}

.text-box .read-more-link  {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    font: inherit;
    color: inherit;
    cursor: pointer;
    outline: inherit;
    color: rgba(247, 144, 9, 1);
}

.policy-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 12px;
}


@media screen and (max-width: 1024px) {
    .cookie-container {
        display: flex;
        flex-direction: column;
        overflow: visible;
        position: static;
        margin: 24px 24px;
    }
}

@media screen and (max-width: 1024px) and (min-width: 767px) {
    .text-box {
        position: static;
    }

    .policy-buttons {
        display: flex;
        flex-direction: row;
        gap: 12px;
    }
    .policy-buttons > button {
        flex: 1;
    }
}

