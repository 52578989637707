
.user-main-content{
	display: flex;
	margin-left: 320px;
	padding-top: 77px;
}

@media (max-width: 1024px){
	.user-main-content{
		margin-left: 0;
		padding-top: 48px;
		padding-bottom: 72px;
	}
}


