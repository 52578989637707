.user-profile-recipes{
	margin-top: 48px;
}

.user-profile-recipes-selection{
	border-bottom: 1px #EAECF0 solid;
	display: flex;
	padding: 0px 16px;
	min-width: 380px;
}

.user-profile-recipes-selection-container{
	overflow: scroll;
	scrollbar-width: none;
}


.user-profile-recipe-option{
	cursor: pointer;
	padding: 11px 16px;
	padding-bottom: 10px;
}


.user-profile-recipe-option-text-selected{
	color: #DC6803;
}

