.cart-item-component{
	display: flex;
	width: 100%;
	box-sizing: border-box;
	padding: 20px 24px;
	gap: 14px;
	border-bottom: 1px solid #DEE2E6;
}

.cart-item-image-container{
	flex-shrink: 0;
	width: 114px;
	height: 106px;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 15px;
}

.cart-item-image-container img{
	min-width: 100%;
	min-height: 100%;
	object-fit: cover;
}

.cart-item-details-container{
	display: flex;
	width: 100%;
	height: 100%;
	gap: 24px;
	align-items: center;
}

.cart-item-text-container{
	display: flex;
	flex-direction: column;
	flex: 1;
	gap: 12px;
}

.cart-item-name{
	font-family: 'Inter';
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
	letter-spacing: 0.02em;
	text-align: left;
	color: #475467;
}

.cart-item-price{
	font-family: 'Inter';
	font-size: 18px;
	font-weight: 500;
	line-height: 28px;
	letter-spacing: 0.02em;
	text-align: left;
	color: black;
}

.cart-item-option-container{
	width: 161px;
	display: flex;
	align-items: center;
	padding: 12px;
	gap: 12px;
	box-sizing: border-box;
	flex-wrap: wrap;
}

.cart-item-quantity-text{
	font-family: 'Inter';
	font-size: 14px;
	font-weight: 400;
	line-height: 16.94px;
	text-align: left;
	color: #495057;
}

.cart-item-option-container input{
	width: 57px;
	height: 44px;
	border: 1px solid #D0D5DD;
	border-radius: 8px;
	box-shadow: 0px 1px 2px 0px #18274B14;
	text-align: center;

	font-family: 'Inter';
	font-size: 18px;
	font-weight: 500;
	line-height: 28px;
	letter-spacing: 0.02em;
	color: #344054;

	box-sizing: border-box;
}

.cart-item-option-container div{
	width: 28px;
	height: 28px;
}

.cart-item-option-container img{
	width: 20px;
	height: 20px;
	cursor: pointer;
}

.cart-item-warning-text{
	width: 100%;
	flex-basis: 100%;
	font-family: 'Inter';
	font-size: 14px;
	font-weight: 400;
	line-height: 16.94px;
	text-align: center;
	color: #FEC84B;
}

@media (max-width: 1024px){
	.cart-item-component{
		align-items: center;
	}
	.cart-item-details-container{
		flex-direction: column;
		align-items: start;
		gap: 12px;
		width: 100%;
	}
	.cart-item-name{
		font-family: 'Inter';
		font-size: 12px;
		font-weight: 400;
		line-height: 20px;
		letter-spacing: 0.02em;
		text-align: left;
		color: #475467;
	}
	.cart-item-price{
		font-family: 'Inter';
		font-size: 16px;
		font-weight: 500;
		line-height: 26px;
		letter-spacing: 0.02em;
		text-align: left;
		color: black;
	}
	.cart-item-option-container{
		width: 100%;
		padding: 8px;
	}
	.cart-item-warning-text{
		flex-basis: fit-content;
		display: flex;
	}
}

@media (max-width: 768px){
	.cart-item-image-container{
		width: 77px;
		height: 72px;
	}
}

