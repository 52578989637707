.recipe-page-instructions-container{
	display: flex;
	flex-direction: column;
	gap: 20px;
}
.recipe-page-instructions-header{
	display: flex;
}

.recipe-page-instruction-list{
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.recipe-page-instruction{
	display: flex;
	gap: 20px;
}

.recipe-page-instruction-num-container{
	width: 36px;
	height: 36px;
	background-color: #FEF0C7;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 999px;
}
.recipe-page-instruction-num-container p{
	font-family: 'Inter';
	font-size: 18px;
	font-weight: 500;
	line-height: 28px;
	letter-spacing: 0.02em;
	text-align: center;
	color: #DC6083;
}

.recipe-page-instruction-detail{
	flex: 1;
	gap: 20px;
	display: flex;
}

.recipe-page-instruction-detail p{
	flex: 1;
	font-family: 'Inter';
	font-size: 16px;
	font-weight: 400;
	line-height: 26px;
	letter-spacing: 0.02em;
	color: #475467;
}
.recipe-page-instruction-img-container{
	width: 228px;
	height: 228px;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
}
.recipe-page-instruction-img-container img{
	object-fit: cover;
	min-height: 100%;
	min-width: 100%;
}

.recipe-page-instruction-separator-container{
	padding-left: 52px;
	width: 100%;
	box-sizing: border-box;
}
.recipe-page-instruction-separator{
	width: 100%;
	height: 0px;
	box-sizing: border-box;
	border-top: 1px #D0D5DD dashed;
}

@media (max-width: 767px){
	.recipe-page-instruction-detail{
		flex-direction: column;
	}
	.recipe-page-instruction-img-container{
		width: 100%;
		height: 286px;
	}
	.recipe-page-instruction-img-container img{
		min-height: auto;
		max-width: 100%;
		height: 286px;
	}
}

