.recipe-page-comments{
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.recipe-page-comments-header{
	display: flex;
	align-items: center;
	gap: 8px;
}
.recipe-page-comment-count{
	font-family: 'Inter';
	font-size: 16px;
	font-weight: 400;
	line-height: 26px;
	letter-spacing: 0.02em;
	text-align: left;

	color: #475467;
}

.recipe-page-comment-input-container{
	display: flex;
	gap: 20px;
	align-items: center;
}

.recipe-page-comment-textarea{
	scrollbar-width: none;  
	resize: none;
	width: 100%;
	height: 44px;
	padding: 9px 16px;
	padding-right: 50px;
	gap: 8px;
	border-radius: 8px;
	border: 1px #d0d5dd solid;
	box-sizing: border-box;

	font-family: 'inter';
	font-size: 16px;
	font-weight: 400;
	line-height: 26px;
	letter-spacing: 0.02em;
}
.recipe-page-comment-input{
	width: 100%;
	height: 44px;
	padding: 9px 16px;
	padding-right: 50px;
	gap: 8px;
	border-radius: 8px;
	border: 1px #D0D5DD solid;
	box-sizing: border-box;

	font-family: 'Inter';
	font-size: 16px;
	font-weight: 400;
	line-height: 26px;
	letter-spacing: 0.02em;
}
.recipe-page-comment-input-mobile{
	width: 100%;
	height: 44px;
	padding: 9px 16px;
	padding-right: 50px;
	gap: 8px;
	border-radius: 8px;
	border: 1px #D0D5DD solid;
	box-sizing: border-box;

	font-family: 'Inter';
	font-size: 16px;
	font-weight: 400;
	line-height: 26px;
	letter-spacing: 0.02em;
	display: none;
}
.recipe-page-comment-submit{
	position: absolute;
	cursor: pointer;
	right: 29px;
}
.recipe-page-comment-submit-container{
	width: 0;
	flex-basis: 0;
	height: 0;
	position: relative;
	display: flex;
	height: 100%;
	padding-bottom: 22px;
	align-items: end;
}
.recipe-page-comment-submit-container img{
	width: 20px;
	height: 20px;
	position: absolute;
	cursor: pointer;
	right: 29px;
	display: none;
}

.recipe-page-comment-empty{
	font-family: 'Inter';
	font-size: 16px;
	font-weight: 400;
	line-height: 26px;
	letter-spacing: 0.02em;
}

.recipe-page-comments-content{
	display: flex;
	gap: 20px;
}

.recipe-page-comment-commenter-name{
	font-family: 'Inter';
	font-size: 16px;
	font-weight: 500;
	line-height: 26px;
	letter-spacing: 0.02em;

	color: #101828;
}
.recipe-page-comment-date{
	font-family: 'Inter';
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
	letter-spacing: 0.02em;
	text-align: left;
	color: #475467;
	flex: 1;
}
.recipe-page-comments-details{
	gap: 8px;
	display: flex;
	flex-direction: column;
	width: 100%;
}
.recipe-page-comments-details>p{
	font-family: 'Inter';
	font-size: 16px;
	font-weight: 400;
	line-height: 26px;
	letter-spacing: 0.02em;
}

.recipe-page-comments-description{
	display: flex;
	align-items: center;
	gap: 8px;
}
.recipe-page-comments-description img{
	width: 20px;
	height: 20px;
}

.recipe-page-comment-separator-container{
	padding-left: 60px;
	width: 100%;
	box-sizing: border-box;
}

.recipe-page-comment-separator{
	width: 100%;
	height: 0px;
	box-sizing: border-box;
	border-top: 1px #D0D5DD dashed;
}

.recipe-page-comments-container{
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.recipe-page-comment-mobile-container{
	display: flex;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #10182899; 
	flex-direction: column;
	justify-content: end;
	display: none;
	z-index: 5;
}

.recipe-page-comment-mobile-container p{
	background-color: white;
	height: 44px;
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: center;

	font-family: 'Inter';
	font-size: 14px;
	font-weight: 500;
	line-height: 22px;
	letter-spacing: 0.02em;
	text-align: center;

	border-radius: 8px 8px 0px 0px;

}

.recipe-page-comment-mobile-blank{
	top: 0;
	left: 0;
	flex: 1;
	width: 100%;
}

.recipe-page-comment-mobile-details{
	border-top: 1px #EAECF0 solid; 
	width: 100%;
	height: fit-content;
	background-color: white; 
	display: flex;
	padding: 11px 16px;
	box-sizing: border-box;
	gap: 20px;
}

.recipe-page-comment-mobile-details>.recipe-page-comment-submit-container{
	padding-bottom: 0;
	bottom: 10px;
}

.recipe-page-comment-mobile-container textarea{
	scrollbar-width: none;  
	resize: none;
	flex: 1;
	height: 44px;
	border-radius: 8px;
	padding: 9px 16px;
	padding-right: 50px;
	gap: 8px;
	border: 1px #d0d5dd solid;
	box-sizing: border-box;

	font-family: 'inter';
	font-size: 16px;
	font-weight: 400;
	line-height: 26px;
	letter-spacing: 0.02em;
}

.recipe-page-comments-mb{
	display: none;
}

.cursor-default{
	cursor: default;
}

@media (max-width: 767px){
	.recipe-page-comment-submit{
		display: none;
	}
	.recipe-page-comment-submit-container img{
		display: block;
	}
	.recipe-page-comment-input{
		padding-right: 35px;
	}
	.recipe-page-comment-textarea{
		display: none;
	}
	.recipe-page-comment-input-mobile{
		display: block;
	}
	.recipe-page-comment-mobile-container{
		display: flex;
	}
	.recipe-page-comments-mb{
		display: block;
	}
}


